<template>
  <div>
    <client-only>
      <van-loading v-if="loading && modelValue > 1" class="flex-center min-h-[80px]"></van-loading>
      <span v-if="!loading && hasNextPage" ref="loadMoreRef" class="min-h-[500px]"></span>
    </client-only>
  </div>
</template>

<script setup>
const props = defineProps({
  modelValue: {
    type: Number,
    default: 1
  },
  itemsLength: {
    type: [Number, String],
    default: 0
  },
  total: {
    type: [Number, String],
    default: 0
  },
  loading: {
    type: Boolean,
    default: false
  },
  // When total is not defined then control the scrolling using infinite
  infinite: {
    type: Boolean,
    default: false
  }
})

const emit = defineEmits(['update:modelValue', 'change'])

const loadMore = () => {
  emit('update:modelValue', props.modelValue + 1)
  emit('change')
}

const hasNextPage = computed(() => props.infinite || props.itemsLength < props?.total);

// load more items on scroll
const loadMoreRef = ref(null)
useIntersectionObserver(
    loadMoreRef,
    ([{isIntersecting}]) => {
      if (isIntersecting && !props.loading) {
        loadMore()
      }
    }
)
</script>

<style scoped>

</style>