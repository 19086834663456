<template>
  <device-wrapper #default="{ desktop }" class="relative">
    <div
        class="flex relative bg-white min-h-[48px] rounded-md items-center px-4"
        dir="ltr"
        v-bind="$attrs"
    >
      <client-only>
        <div class="flex items-center cursor-pointer min-w-[80px]" @click="showList = true">
          <niceone-image :src="selectedCountry.thumb" width="24"/>
          <div class="mx-3">
            {{ COUNTRY_CODE_BY_ISO[selectedCountry.iso_code_2] }}
          </div>
        </div>
        <input v-model="phoneNumber" class="w-full" type="tel" @change.stop/>

        <template v-if="desktop">
          <div v-if="showList" ref="countriesListRef"
               class="absolute left-3 bg-white top-8 left-0 z-10 shadow-lg min-w-[250px] max-h-[300px] overflow-y-auto">
            <countries-list v-model="countryCode" :sa-only="saOnly" @click="showList = false"></countries-list>
          </div>
        </template>
      </client-only>


    </div>
    <template v-if="!desktop">
      <van-popup
          v-model:show="showList"
          :style="{ height: '45%' }"
          class="pt-3"
          position="bottom"
      >
        <!-- <van-search placeholder="Search" v-model="searchValue" /> -->
        <countries-list v-model="countryCode" :sa-only="saOnly" @click="showList = false">
          <template #label>
            <span class="px-3">{{ $t('menu.countries') }}</span>
          </template>
        </countries-list>
      </van-popup>
    </template>
  </device-wrapper>

</template>

<script setup>
import CountriesList from "@/components/common/CountriesList.vue";
import {useCountryStore} from "~/store/countries";
import {ISO_CODES, COUNTRY_CODE_BY_ISO, ISO_CODE_BY_COUNTRY_CODE} from "@/helper";
import {onClickOutside} from "@vueuse/core/index";

const {countries} = useCountryStore();
const props = defineProps({
  modelValue: String || Number,
  saOnly: {
    type: Boolean,
    default: false
  }
});
const emit = defineEmits(["update:modelValue"]);
const mobile = computed({
  get: () => props.modelValue || "",
  set: (value) => emit("update:modelValue", value),
});

const showList = ref(false);
const countryCode = ref(ISO_CODES.SA);
const selectedCountry = computed(() => {
  if (!countries.length) return {};
  if (!countryCode.value) return countries[0];
  return countries.find((item) => item.iso_code_2 === countryCode.value);
});

const formatPhoneNumber = (phone) => {
  if (!phone) return;

  phone = phone.toString().replace(/\D/g, ""); // Remove non-digit characters

  for (const isoCode in COUNTRY_CODE_BY_ISO) {
    const code = COUNTRY_CODE_BY_ISO[isoCode].replace(/\+/g, ""); // Remove plus sign
    if (phone.startsWith(code)) {
      phone = phone.substring(code.length);
      countryCode.value = ISO_CODE_BY_COUNTRY_CODE[code] || ISO_CODES.SA
      break; // Found a matching code, no need to continue checking
    }
  }

  return phone;
};

const countriesListRef = ref(null)
onClickOutside(countriesListRef, () => showList.value = false)

const phoneNumber = ref(formatPhoneNumber(mobile.value));
watch(
    phoneNumber,
    () => {
      mobile.value = `${COUNTRY_CODE_BY_ISO[selectedCountry.value.iso_code_2]}${
          phoneNumber.value
      }`;
    },
    {immediate: true}
);

watch(mobile, () => {
  phoneNumber.value = formatPhoneNumber(mobile.value)
})

watch(countryCode,
    () => {
      phoneNumber.value = ""
    }
)
</script>
