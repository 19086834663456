<template>
  <!--  <van-image v-bind="{ defaultAttrs, ...attrs }">-->
  <!--    <template #loading>-->
  <!--      <van-loading size="20px" type="spinner"/>-->
  <!--    </template>-->
  <!--  </van-image>-->
  <!--  <span v-if="loading" class="absolute flex justify-center items-center w-full h-full">-->
  <!--        <van-loading size="20px" type="spinner"/>-->
  <!--    </span>-->
  <nuxt-img v-if="!isError && $attrs.src" loading="lazy"
            v-bind="{ ...defaultAttrs, ...$attrs, src: $attrs.src ? $attrs.src : '/', alt: $attrs.alt || generateAlt }"
            @error="isError = true" @load="loading = false">
  </nuxt-img>
</template>

<script setup>
const defaultAttrs = {
  // placeholder: true,
  // lazyLoad: true,
  // showLoading: true,
}
const attrs = useAttrs()
const loading = ref(true)
const isError = ref(false)
const generateAlt = computed(() => {
  return attrs.src?.match(/\/([^/]+)\.[^.]+$/)?.[1] || '';
})
</script>