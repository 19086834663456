<template>
  <div>
    <van-loading v-if="shareLoading" size="18"/>
    <niceone-image v-else :src="$publicPath('/images/cart-share.svg')" width="22" @click="shareCart"/>
  </div>
</template>

<script setup>
import {shareCartMutation} from "@/plugins/API/cart";
import {useShareLink} from "@/composables/useShareLink";
import {useAuthStore} from "@/store/auth";
import {useEventBus} from "@vueuse/core";
import {EVENTS} from "~/constants/events";

const authStore = useAuthStore()
const {pendingRedirection, isLoggedIn} = storeToRefs(authStore)

const {emit: emitAuth} = useEventBus(EVENTS.AUTH_DIALOG);

const {isLoading: shareLoading, mutateAsync: shareMutateAsync} = shareCartMutation()
const {share} = useShareLink()

const router = useRouter()
const shareCart = () => {
  if (!isLoggedIn.value) {
    pendingRedirection.value = '/cart'
    return useNuxtApp().$device?.isDesktop ? emitAuth({redirection: false}) : router.push(useNuxtApp().$routerUrl('/login'))
  }
  shareMutateAsync().then(({data}) => {
    share(data.url)
  })
}
</script>

<style scoped>

</style>