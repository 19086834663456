<template>
  <div :style="`min-height: ${minHeight}px`" class="flex items-center">
    <div v-if="!isSpecial" :style="`font-size: ${14 + increaseFontBy}px`" class="font-niceone-medium">
      {{ !!selectedOption ? selectedOption[priceKey] : product[priceKey] }}
    </div>
    <div v-else>
      <div :style="`font-size: ${11 + increaseFontBy}px`" class="line-through text-gray-500">
        {{ special?.original_price || selectedOption?.price_formated }}
      </div>
      <div class="flex items-center">
        <div :style="`font-size: ${14 + increaseFontBy}px`"
             class="text-discount-color font-niceone-medium me-2 whitespace-nowrap">
          {{ special?.price_formated }}
        </div>
        <div v-if="!hideDiscountRatio" class="px-2 flex items-center h-4 bg-lava-light rounded-full">
          <div class="text-xs font-medium text-discount-color" dir="ltr">
            {{ special?.discount_ratio }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  product: {
    type: Object,
    default: () => {
    }
  },
  priceKey: {
    type: String,
    default: "price_formated"
  },
  minHeight: {
    type: Number,
    default: 44
  },
  increaseFontBy: {
    type: Number,
    default: 0
  },
  selectedOption: {
    type: Object,
    default: () => null
  },
  hideDiscountRatio: {
    type: Boolean,
    default: false
  }
})

const isSpecial = computed(() => !!props.selectedOption ? !!props.selectedOption?.specials?.length : !!props?.product?.special?.length)
const special = computed(() => !!props.selectedOption ? props.selectedOption?.specials[0] || {} : isSpecial.value && props?.product?.special[0] || {})
</script>