<template>
  <van-form class="h-full flex flex-col justify-between p-3 bg-light-grey">
    <slot name="header">
      <div class="flex items-center justify-between">
        <niceone-image :src="$publicPath('/images/logo.svg')" width="150"/>
        <div v-if="!$device.isDesktop" class="p-2 text-sm" @click="close">{{ $t('common.close') }}</div>
      </div>

    </slot>

    <div class="overflow-y-auto h-full">
      <div :class="[$device.isDesktop?'mt-5 text-base':'mt-[150px] text-[20px]']" class="mb-3  font-weight-500">
        <slot name="title"/>
      </div>
      <slot></slot>
    </div>

    <div>
      <slot name="actions"/>
      <van-button :disabled="loading" :loading="loading" block class="sticky bottom-0 font-bold"
                  native-type="submit" type="primary">
        {{ submiButtontName }}
      </van-button>
    </div>
  </van-form>
</template>

<script setup>
import {useAuthStore} from '@/store/auth'

const emit = defineEmits('formSubmit')

defineProps({
  submiButtontName: {
    type: String,
    default: ''
  },
  loading: {
    type: Boolean,
    default: false
  }
})

const authStore = useAuthStore()

const router = useRouter()
const close = () => {
  authStore.resetType()
  router.push(useNuxtApp().$routerUrl('account'))
}
</script>