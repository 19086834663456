<template>
  <div class="flex items-center justify-center h-[48px] text-black text-sm gap-1">
    <span><niceone-image :src="$publicPath('/images/icon-profile.svg')" width="18"/></span>
    <span class="hidden md:block">{{ $t('profile.profile') }}</span>
    <van-icon name="arrow-down" size="16"></van-icon>
  </div>
  <ul v-if="isLoggedIn"
      class="hidden group-hover:block absolute bg-[#f9f9f9] me-2 ltr:right-0 rtl:left-0 shadow-cus top-[100%] w-[360px] z-10 border-t-2 border-black rounded-b-md">
    <li class="border-b-[0.7px] border-b-[#e1e1e1]">
      <nuxt-link :to="$routerUrl('profile')" class="flex items-center justify-between p-3">
          <span class="flex flex-col">
            <span class="text-[#202020] text-base font-semibold">
             {{ user.firstname }} {{ user.lastname }}
            </span>
            <span class="text-xs text-txt-secondary">
              {{ user.email }}
            </span>
            <span class="text-xs text-txt-secondary">
              {{ user.telephone }}
            </span>
          </span>
        <van-icon class="flip-icon" color="#969799" name="arrow" size="16"/>
      </nuxt-link>
    </li>
    <li class="border-b-[0.7px] border-b-[#e1e1e1]">
      <profileShotLinks/>
    </li>
    <li v-if="isLoggedIn && user?.current_membership?.tier_type" class="mx-3 mt-3 mb-1">
      <nuxt-link :to="$routerUrl('/loyalty')">
        <loyalty-card :member-ship="user?.current_membership" class="p-2" minimize
                      show-arrow/>
      </nuxt-link>
    </li>
    <nuxt-link
        v-for="(item, index) in getItems"
        :key="`link-${index}`"
        :to="!item.redirect && $routerUrl(item.link)"
        tagert="_blank"
    >
      <NavCell :icon-size="16" :title="item.title" @click="item.onClick && item.onClick()">
        <template #icon>
          <van-icon :name="$publicPath(item.icon)" class="ps-3" size="28"/>
        </template>
      </NavCell>
    </nuxt-link>

    <NavCell :divider="false" :icon-size="16" :title="$t('menu.logout')" class="pb-1" @click="logout">
      <template #icon>
        <van-icon :name="$publicPath('/images/logout.svg')" class="ps-3" size="28"/>
      </template>
    </NavCell>
  </ul>
  <div v-if="!isLoggedIn"
       class="p-4 hidden flex flex-col space-y-2 mx-auto group-hover:block absolute bg-[#f9f9f9] me-2 ltr:right-0 rtl:left-0 shadow-cus top-[100%] w-[360px] z-10 border-t-2 border-black">
    <div class="p-1">
      <van-button block class="!rounded-lg font-niceone-bold !h-[36px]" type="primary" @click="openAuth">
        {{ $t('auth.login') }}
      </van-button>
    </div>
    <div>
      <div class="text-[12px] cursor-pointer font-niceone-normal text-boulder text-center"
           @click="openAuth({isRegister: true})">{{ $t('auth.dont_have_account') }}<span
          class="hover:text-secondary">{{ $t('auth.register') }}</span></div>
    </div>
  </div>
</template>

<script setup>
import {useAuthStore} from '@/store/auth'
import {storeToRefs} from "pinia";
import {AUTH_TYPES} from "@/constants";
import profileShotLinks from '@/components/profile/ProfileShotLinks'
import {logoutUser} from "@/plugins/API/auth";
import {useEventBus} from "@vueuse/core";
import {EVENTS, TRACKERS} from "@/constants/events";
import {track} from "@/composables/useTrackEvent";
import LoyaltyCard from "@/components/loyalty/Card";
import NavCell from "@/components/common/NavCell";
import {useVoucherStore} from "@/store/voucher";

const emit = defineEmits(['showAuth', 'hideOverlay'])

const authStore = useAuthStore();
const {isLoggedIn, user} = storeToRefs(authStore)

const {t} = useI18n();
const links = [
  {
    show: () => isLoggedIn.value,
    title: t("voucher.gift_a_voucher"),
    icon: "/images/account/buy-gift.svg",
    link: "voucher",
    onClick: () => resetVoucherStore()
  },
  {
    show: () => user?.value?.show_rewards && isLoggedIn.value,
    title: t('rewards.reward'),
    icon: "/images/rewards/reward-icon-small.svg",
    link: "reward",
  },
  {
    show: () => isLoggedIn.value,
    title: t("profile.my_addresses"),
    icon: "/images/account/address.svg",
    link: "addresses",
  },
  {
    show: () => isLoggedIn.value && !!user.value?.referral,
    title: t("profile.invite_a_friend"),
    icon: "/images/sidebar/referral.svg",
    link: "referral",
  }
];
const getItems = computed(() => links.filter((item) => item.show()));

const {emit: emitAuth} = useEventBus(EVENTS.AUTH_DIALOG);
const openAuth = ({isRegister = false} = "") => {
  authStore.changeType(isRegister ? AUTH_TYPES.REGISTER : AUTH_TYPES.LOGIN_PHONE)
  emitAuth()
  track(isRegister ? TRACKERS.REGISTER_INITIATE : TRACKERS.LOGIN_INITATE)
}

const router = useRouter()

const {isLoading, mutateAsync} = logoutUser()
const logout = () => {
  emit('hideOverlay')
  mutateAsync().then(() => {
    router.push(useNuxtApp().$routerUrl('/'))
  })
}

const voucherStore = useVoucherStore()
const resetVoucherStore = () => {
  voucherStore.$reset()
}
</script>

<style lang="scss" scoped></style>
