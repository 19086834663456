const subDomains = ['gift', 'gift-test'];

export default {
    routes: (_routes) => {
        const {ssrContext} = useNuxtApp();

        const subdomain = useState("subdomain", () => ssrContext?.event.context.subdomain);

        if (subdomain.value && subDomains.includes(subdomain.value)) {
            const userRoute = _routes.filter((route) => {
                const regex = new RegExp(`/(en|ar)/${subdomain.value}/.*`);
                return regex.test(route.path);
            });

            return userRoute.map((route) => {
                const path = route.path.startsWith(`/${subdomain.value}`)
                    ? route.path.replace(`/${subdomain.value}`, '/')
                    : route.path.replace(`/${subdomain.value}/`, '/');
                return {...route, path};
            });
        }
    },
};