<template>
  <simple-wrapper class="bg-light-grey" hide-shadow>
    <template #header>
      <van-nav-bar :title="isLoggedIn ? $t('menu.notifications') : $t('menu.offers')" left-arrow>
        <template #left>
          <slot name="icon">
            <van-icon class="flip-icon" name="arrow-left" size="22" @click="$router.back()"/>
          </slot>
        </template>
      </van-nav-bar>
    </template>

    <van-tabs v-if="isLoggedIn" v-model:active="activeTab" line-height="0" offset-top="50" sticky>
      <van-tab v-for="(tab, index) in tabs" :key="`tab-${index}`" :name="tab.slug" :title="tab.name">
        <template v-if="activeTab === tab.slug">
          <Component :is="activeComponent"/>
        </template>
      </van-tab>
    </van-tabs>

    <offers v-else/>

  </simple-wrapper>
</template>

<script setup>
import {NOTIFICATION_TABS} from "@/constants";
import Messages from "@/components/notifications/Messages";
import Offers from "@/components/notifications/Offers";
import {useAuthStore} from "@/store/auth";
import {track} from "@/composables/useTrackEvent";
import {TRACKERS} from "@/constants/events";

const authStore = useAuthStore();
const {isLoggedIn} = storeToRefs(authStore);
const {t} = useI18n();
const tabs = ref([
  {
    id: 1,
    slug: NOTIFICATION_TABS.MESSAGES,
    name: t('menu.messages')
  },
  {
    id: 2,
    slug: NOTIFICATION_TABS.OFFERS,
    name: t('menu.offers')
  },
])

const components = {
  [NOTIFICATION_TABS.MESSAGES]: Messages,
  [NOTIFICATION_TABS.OFFERS]: Offers,
};

const activeTab = ref(NOTIFICATION_TABS.MESSAGES)
const activeComponent = computed(() => components[activeTab.value]);
onMounted(() => {
  track(TRACKERS.OPEN_NOTIFICATION_CENTER_PAGE)
})
</script>

<style scoped>
</style>