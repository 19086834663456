import {useMutation, useQuery, useQueryClient} from "@tanstack/vue-query";

export function getCartQuery() {
    const queryKey = ["cart"];

    return useQuery(queryKey, () => {
        return useNuxtApp()
            .$axios("?route=rest/cart/cart")
            .then((response) => response?.data || {});
    });
}

export function addCartQuery() {
    const queryClient = useQueryClient();
    return useMutation(
        ({id, option = "", quantity = 1}) => {
            const params = {
                product_id: id,
                option,
                quantity,
            };
            return useNuxtApp().$axios.post("?route=rest/cart/cart", params);
        },
        {
            onSuccess: ({data}) => {
                const cartData = queryClient.getQueryData(["cart"]);
                const newData = {...cartData, cart: data?.cart};
                queryClient.setQueryData(["cart"], newData);
            },
        }
    );
}

export function updateCartQuery() {
    const queryClient = useQueryClient();
    return useMutation(
        ({key, quantity}) => {
            return useNuxtApp().$axios.put("?route=rest/cart/cart", {key, quantity});
        },
        {
            onSuccess: ({data}) => {
                const cartData = queryClient.getQueryData(["cart"]);
                const newData = {...cartData, cart: data?.cart};
                queryClient.setQueryData(["cart"], newData);
            },
        }
    );
}

export function removeCartQuery() {
    const queryClient = useQueryClient();
    return useMutation(
        ({key}) => {
            return useNuxtApp().$axios.delete("?route=rest/cart/cart", {data: {key}});
        },
        {
            onSuccess: ({data}) => {
                const cartData = queryClient.getQueryData(["cart"]);
                const newData = {...cartData, cart: data?.cart};
                queryClient.setQueryData(["cart"], newData);
            },
        }
    );
}

export function addMultipleCartMutation() {
    const queryClient = useQueryClient();
    return useMutation(
        ({params = {}}) => {
            return useNuxtApp().$axios.post("?route=rest/cart/add_items", {params});
        },
        {
            onSuccess: ({data}) => {
                const cartData = queryClient.getQueryData(["cart"]);
                const newData = {...cartData, cart: data?.cart};
                queryClient.setQueryData(["cart"], newData);
            },
        }
    );
}

export function getWishlistQuery() {
    const queryKey = ["wishlist"];

    return useQuery(queryKey, () => {
        return useNuxtApp()
            .$axios("?route=rest/wishlist/wishlist")
            .then((response) => response?.data || {});
    });
}

export function addWishlistMutation() {
    const queryClient = useQueryClient();
    return useMutation(
        ({id, option = {}}) => {
            const params = {
                product_id: id,
                option
            };
            return useNuxtApp().$axios.post("?route=rest/wishlist/wishlist", params);
        },
        {
            onSuccess: () => {
                return queryClient.invalidateQueries(["wishlist"]);
            },
        }
    );
}

export function removeWishlistMutation() {
    const queryClient = useQueryClient();
    return useMutation(
        ({id, option = {}}) => {
            return useNuxtApp().$axios.delete("?route=rest/wishlist/wishlist", {
                data: {product_id: id, option},
            });
        },
        {
            onSuccess: () => {
                return queryClient.invalidateQueries(["wishlist"]);
            },
        }
    );
}

export function shareCartMutation() {
    return useMutation(() => {
        return useNuxtApp().$axios.post("?route=rest/cart_shared/share");
    });
}

export function getShareCartByCodeMutation() {
    return useMutation((code = "") => {
        return useNuxtApp().$axios.get("?route=rest/cart_shared/share", {
            params: {code},
        });
    });
}

export function mergeCartMutation() {
    const queryClient = useQueryClient();
    return useMutation(
        ({params}) => {
            return useNuxtApp().$axios.post("?route=rest/cart/add_items", {
                params: params,
            });
        },
        {
            onSuccess: () => {
                return queryClient.invalidateQueries(["cart"]);
            },
        }
    );
}
