<template>
  <niceone-field v-model="code" :placeholder="$t('verify.enter_verification_code')" autocomplete="one-time-code"
                 class="border rounded"
                 dir="ltr" maxlength="4"
                 type="digit"
                 v-bind="$attrs"/>
</template>

<script setup>
const props = defineProps({
  modelValue: {
    type: [String, Number],
    default: ""
  },
})
const emit = defineEmits(['update:modelValue'])

const code = computed({
  get() {
    return props.modelValue
  },
  set(val) {
    emit('update:modelValue', val)
  }
})
</script>


<style scoped>

</style>