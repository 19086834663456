<template>
  <div class="mb-3 text-xs">
    <slot name="label"/>
  </div>
  <van-cell-group v-bind="$attrs">
    <van-cell v-for="(item, index) in getCountries" :key="`link-${index}`" :title="item.name"
              class="cursor-pointer" @click="countryValue = item.iso_code_2">
      <template #icon>
        <niceone-image :src="item.thumb" class="ltr:mr-3 rtl:ml-3" width="22"></niceone-image>
      </template>
      <template #value>
        <van-icon v-if="countryValue === item.iso_code_2" color="#000" name="success"/>
      </template>
    </van-cell>
  </van-cell-group>
</template>

<script setup>
import {useCountryStore} from '~/store/countries'
import {storeToRefs} from "pinia";
import {ISO_CODES} from "@/helper";

const countryStore = useCountryStore()
const {countries} = storeToRefs(countryStore)

const props = defineProps({
  modelValue: String,
  saOnly: {
    type: Boolean,
    default: false
  }
})
const emit = defineEmits(['update:modelValue'])
const countryValue = computed({
  get: () => props.modelValue,
  set: (value) => emit('update:modelValue', value)
})

const getCountries = computed(() => {
  if(props.saOnly) return countries.value.filter(item => item.iso_code_2 === ISO_CODES.SA)
  return countries.value
})
</script>