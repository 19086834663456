import {SignJWT} from 'jose'
import useCustomConfig from "~/composables/useEnvConfig";

export const useJwtToken = () => {
    const config = useCustomConfig();
    const secret = new TextEncoder().encode(config.public.JWT_SECRET)
    const getToken = async () => {
        return new Promise((resolve) => {
            resolve(new SignJWT({'urn:example:claim': true})
                .setProtectedHeader({alg: 'HS256'})
                .setExpirationTime('5m')
                .sign(secret))
        })
    }
    return {
        getToken
    }
}