export const useCategoryStore = defineStore('category', () => {
    const {t} = useI18n()
    const categories = ref([])

    const openPageFrom = ref('')

    const getCategories = computed(() => {
        return categories.value?.map(item => {

            const allProductsCategory = {
                name: t('common.all_products'),
                isAllProduct: true,
                seo_url: item.seo_url,
                categories: []
            };

            return {
                ...item,
                categories: [allProductsCategory, ...item.categories]
            }
        })
    })

    const addCategories = (cbCategories) => {
        categories.value = cbCategories
    }

    return {
        categories,
        getCategories,
        addCategories,
        openPageFrom
    }
})