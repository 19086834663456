export const useCountryStore = defineStore('country', () => {

    const countries = ref([])
    const addCountries = (cbCountries) => {
        countries.value = cbCountries
    }

    return {
        countries,
        addCountries
    }
})