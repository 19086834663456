export const useCustomFetch = async (url, params = null) => {

    const {$axios} = useNuxtApp()

    const {headers, baseURL} = $axios.defaults

    const {locale} = useI18n()

    const {data, pending} = await useLazyFetch(`${baseURL}/${url}`, {
        params,
        headers: {
            ...headers,
            "x-oc-merchant-language": locale.value,
        },
        transform: (data) => JSON.parse(data)?.data
    })

    return {data, loading: pending}
}