<template>
  <div v-if="!loading && cartProducts?.length" class="sticky bg-white p-3 z-2" v-bind="$attrs">
    <cart-progress/>

    <div class="-mx-3">
      <van-divider class="py-2"/>
    </div>

    <div class="flex items-center justify-between">
      <div class="flex flex-col">
        <div class="text-base" v-html="cart?.total"></div>
        <div class="text-error text-[11px]" v-html="cart?.cart_total_saved"></div>
      </div>
      <van-button v-if="cartProducts?.length" :disabled="!allInStock" class="min-w-[176px] !rounded-lg"
                  type="primary" @click="checkout">
        {{ getButtonTitle }}
      </van-button>
    </div>
  </div>
</template>

<script setup>
import {useCartStore} from "@/store/cart";
import {useAuthStore} from "@/store/auth";
import {storeToRefs} from "pinia";
import {track} from "@/composables/useTrackEvent";
import {EVENTS, TRACKERS} from "@/constants/events";
import CartProgress from "@/components/cart/Progress"

const emit = defineEmits(['closeSidebar'])

const cartStore = useCartStore()
const {loading, cart, allInStock, cartProducts} = storeToRefs(cartStore)

const {t} = useI18n()
const getButtonTitle = computed(() => {
  if (!allInStock.value) return t('product.an_item_is_out_of_stock')
  return t('checkout.checkout')
})

const {emit: emitAuth} = useEventBus(EVENTS.AUTH_DIALOG);
const router = useRouter()
const authStore = useAuthStore()
const {pendingRedirection, isLoggedIn} = storeToRefs(authStore)
const checkout = () => {
  track(TRACKERS.CHECKOUT_1_SHOPPING_BAG)
  pendingRedirection.value = '/checkout/address/list'

  emit('closeSidebar')

  if (!isLoggedIn.value && useNuxtApp().$device.isDesktop) {
    return emitAuth()
  }

  router.push(useNuxtApp().$routerUrl('/checkout/address/list'))
}
</script>

<style scoped>

</style>