import {useQuery} from '@tanstack/vue-query';

export function privacyQuery() {

    const queryKey = ['privacy'];

    return useQuery(
        queryKey,
        () => {
            return useNuxtApp().$axios("?route=rest/information/information&id=3").then(response => response?.data || {})
        }
    );
}

export function termsQuery() {

    const queryKey = ['terms'];

    return useQuery(
        queryKey,
        () => {
            return useNuxtApp().$axios("?route=rest/information/information&id=5").then(response => response?.data || {})
        }
    );
}

export function rewardsTermsQuery() {

    const queryKey = ['terms'];

    return useQuery(
        queryKey,
        () => {
            return useNuxtApp().$axios("https://api.niceonesa.com?route=rest/information/information&id=15").then(response => response?.data || {})
        }
    );
}

export function rewardQuery() {

    const queryKey = ['reward'];

    return useQuery(
        queryKey,
        () => {
            return useNuxtApp().$axios("?route=rest/information/information&id=14").then(response => response?.data || {})
        }
    );
}

export function refundQuery() {

    const queryKey = ['refund'];

    return useQuery(
        queryKey,
        () => {
            return useNuxtApp().$axios("?route=rest/information/information&id=6").then(response => response?.data || {})
        }
    );
}

export function accountDeleteQuery() {

    const queryKey = ['account-delete'];

    return useQuery(
        queryKey,
        () => {
            return useNuxtApp().$axios("?route=rest/information/information&id=12").then(response => response?.data || {})
        }
    );
}

export function loyaltyTermsQuery() {

    const queryKey = ['loyalty-terms'];

    return useQuery(
        queryKey,
        () => {
            return useNuxtApp().$axios("?route=rest/v4/loyalty/terms_and_condition").then(response => response?.data || {})
        }
    );
}