import {useMutation} from '@tanstack/vue-query';
import useCustomConfig from "@/composables/useEnvConfig";
import {DEFAULT_HEADERS} from "@/constants"

export function getNotificationsMutation() {
    const config = useCustomConfig();
    const {locale} = useI18n()

    return useMutation(
        ({page = 1, limit = 10}) => {
            const {authorization} = useNuxtApp().$axios.defaults.headers
            return $fetch(`${config.public.NOTIFICATION_BASE_URL}/api/notifications`, {
                headers: {
                    authorization,
                    'x-oc-merchant-language': locale.value,
                    ...DEFAULT_HEADERS
                },
                params: {page, limit}
            })
        }
    );
}

export function getOfferNotificationMutation() {
    return useMutation(
        ({page = 1, limit = 10}) => {
            return useNuxtApp().$axios("?route=rest/v4/notification_center/getOffers", {
                params: {page, limit}
            })
        }
    );
}