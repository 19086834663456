<template>
  <van-popup
      v-model:show="model"
      :position="$i18n.locale === 'en' ? 'right' : 'left'"
      :style="{ width: !$device.isDesktop ? '100%' : '500px', height: '100%', zIndex: 9999999 }"
  >
    <simple-wrapper>
      <template #header>
        <van-nav-bar :title="$t('auth.terms_and_conditions')" class="!bg-light-grey" @click-left="onClose">
          <template #left>
            <van-icon name="cross" size="28"/>
          </template>
        </van-nav-bar>
      </template>

      <terms-and-conditions v-if="model" hide-title/>

    </simple-wrapper>
  </van-popup>
</template>

<script setup>
import TermsAndConditions from "@/components/TermsAndConditions";
import {useEventBus} from "@vueuse/core";
import {EVENTS} from "@/constants/events";

const model = ref(false)
const {on: onTermsDialog} = useEventBus(EVENTS.TERMS_DIALOG);
onTermsDialog(({dialog = true} = {}) => {
  model.value = dialog
})

const onClose = () => {
  model.value = false
}
</script>

<style scoped>
</style>