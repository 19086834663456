import {useMutation} from "@tanstack/vue-query";

export function getAllOrders() {
    return useMutation(({page = 1, limit = 20}) => {
        return useNuxtApp().$axios.get("?route=rest/order/orders", {
            params: {limit, page},
        });
    });
}

export function getOrderById() {
    return useMutation((order_id) => {
        return useNuxtApp().$axios.get("?route=rest/order/orders&id=" + order_id);
    });
}

export function cancelOrderMutation() {
    return useMutation(({id, reason, reason_id}) => {
        return useNuxtApp().$axios.post("?route=rest/order/cancel_order", {id, reason, reason_id});
    });
}

export function reOrderMutation() {
    return useMutation((order_id) => {
        return useNuxtApp().$axios.post("?route=rest/order/reaccept_order", {order_id});
    });
}

export function verifyMutation() {
    return useMutation((order_id, code) => {
        return useNuxtApp().$axios.post("?route=rest/order/reaccept_order_verify", {
            order_id, code
        });
    });
}

export function reviewOrderMutation() {
    return useMutation(({order_id, item_quality, speed, delivery_man, other}) => {
        return useNuxtApp().$axios.post("?route=rest/order/review_order", {
            order_id, item_quality, speed, delivery_man, other
        });
    });
}

export function getInvoiceByIdMutation() {
    return useMutation((order_id = "") => {
        return useNuxtApp().$axios.get("?route=rest/order/einvoice", {
            params: {order_id},
        });
    });
}

export function getSingleProductReview() {
    return useMutation((id) => {
        return useNuxtApp().$axios.get("?route=rest/reviews/product_review", {
            params: {product_id: id},
        });
    });
}
