<template>
  <div class="m-3">
    <div class="flex mb-2">
      <div class="text-base font-niceone-medium">{{ $t('product.recommended_products') }}</div>
    </div>
    <grid-products :products="items?.recommended_products?.products" hide-fav-btn v-bind="$attrs"/>
  </div>
</template>

<script setup>
import {useCartStore} from '@/store/cart'
import GridProducts from "@/components/cart/GridProducts";

defineOptions({
  inheritAttrs: false
})

const cartStore = useCartStore()
const {items} = storeToRefs(cartStore)
</script>