<template>
  <section-layout v-if="!isLoading" class="custom-container">
    <van-loading
        v-if="isLoading"
        class="flex-center min-h-128"
        size="30"
    >
    </van-loading>
    <template v-if="!isLoading">
      <div v-if="!hideTitle" class="flex-center mb-3 text-boulder text-black text-lg font-niceone-bold hidden sm:flex">
        {{ data?.title }}
      </div>
      <div class="flex-center mb-3 text-boulder">{{ $t('pages.last_update', {last_updated: data?.last_updated}) }}</div>
      <div v-html="data?.description"></div>
    </template>
  </section-layout>
</template>

<script setup>
import {termsQuery} from "@/plugins/API/content";

defineProps({
  hideTitle: {
    type: Boolean,
    default: false
  }
})

const {isLoading, data} = termsQuery()
</script>

<style scoped>

</style>