import {useCurrencyStore} from "@/store/currency";
import useCustomConfig from "@/composables/useEnvConfig";
import {useAuthStore} from "@/store/auth";

/**
 * Whether apple pay is supported
 * @type {ComputedRef<boolean>}
 */
export const isApplePaySupported = computed(() => {
    const config = useCustomConfig()
    return !!(window?.ApplePaySession && ApplePaySession?.canMakePaymentsWithActiveCard(config.public.APPLE_PAY_MERCHANT_IDENTIFIER))
})

/**
 * Return radius
 * @param bounds
 * @param location
 * @returns {number}
 */
export const getDistanceFromLatLonInKm = (bounds, location) => {
    const ne = bounds.getNorthEast()
    // const sw = bounds.getSouthWest()

    const lat1 = ne.lat()
    const lon1 = ne.lng()
    const {lat: lat2, lng: lon2} = location

    const radius = 6371000 // Radius of the earth in meters
    const dLat = deg2rad(lat2 - lat1) // deg2rad below
    const dLon = deg2rad(lon2 - lon1)
    const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(deg2rad(lat1)) *
        Math.cos(deg2rad(lat2)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2)
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
    return radius * c // Distance in meters
}

const deg2rad = (deg) => {
    return deg * (Math.PI / 180)
}

/**
 * Return formatted currency according to the language
 * @returns {*}
 *
 * @example
 *  @returns 'SAR' for English
 *  @returns 'ريال' for Arabic
 */
export const getFormattedCurrency = () => {
    const {t} = useI18n()

    const currencyStore = useCurrencyStore()
    const {currency} = storeToRefs(currencyStore)

    return t(`currencies.${currency.value}`)
}

/**
 * Return formatted link
 * @param {string} url
 * @returns {string}
 *
 * @example
 *  @param url = 'makeup'
 *  @returns 'https://niceonesa.com/ar/makeup'
 *
 */
export const getFormattedWebsitePath = (url = '') => {

    const {$routerUrl} = useNuxtApp()

    // Website URL
    const config = useCustomConfig();
    const websiteUrl = config.public.WEBSITE_URL

    return `${websiteUrl}${$routerUrl(url)}`
}

export const isAppView = computed(() => {
    const route = useRoute()
    return route.query?.device && route.query?.device === 'app'
})

export const setAndFetchUser = (data = null) => {
    const authStore = useAuthStore();
    const {setUser, fetchUser} = authStore;
    setUser(data);
    fetchUser()
}