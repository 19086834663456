<template>
  <div class="form-group flex justify-between items-center">
    <label class="text-sm sm:text-base font-niceone-normal">{{ title }}</label>
    <div class="flex justify-between gap-6 min-w-[174px] sm:min-w-[238px]">
      <div class="flex items-center flex-col space-y-1">
        <niceone-image :src="$publicPath(`/images/updated-emoji/great${model===0?'-active':''}.png`)"
                       class="cursor-pointer"
                       width="42"
                       @click="model = 0"/>
        <p :class="[model === 0?'text-secondary':'text-grey-muted']" class="text-xs font-niceone-medium">
          {{ emojiText ? $t('feedback.great') : $t('feedback.satisfied') }}</p>
      </div>
      <div class="flex items-center flex-col space-y-1">
        <niceone-image :src="$publicPath(`/images/updated-emoji/ok${model === 1?'-active':''}.png`)"
                       class="cursor-pointer"
                       width="42"
                       @click="model = 1"/>
        <p :class="[model === 1?'text-warning':'text-grey-muted']" class="text-xs font-niceone-medium">
          {{ emojiText ? $t('feedback.ok') : $t('feedback.decent') }}
        </p>
      </div>
      <div class="flex items-center flex-col space-y-1">
        <niceone-image :src="$publicPath(`/images/updated-emoji/bad${model === 2?'-active':''}.png`)"
                       class="cursor-pointer"
                       width="42"
                       @click="model = 2"/>
        <p :class="[model === 2?'text-error':'text-grey-muted']" class="text-xs font-niceone-medium fon">
          {{ emojiText ? $t('feedback.bad') : $t('feedback.not_satisfied') }}</p>
      </div>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  modelValue: {
    type: [Number],
    default: null,
  },
  customerSatisfaction: {
    type: [Number],
    default: null,
  },
  responseTime: {
    type: [Number],
    default: null,
  },
  title: {
    type: String,
    default: '',
  },
  emojiText: {
    type: Boolean,
    default: true,
  }
});

const emit = defineEmits(['update:modelValue']);

const model = computed({
  get() {
    return props.modelValue;
  },
  set(val) {
    emit('update:modelValue', val);
  },
});
</script>

