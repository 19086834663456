<template>
  <div class="ltr:pl-3 rtl:pr-3 min-w-[30px]">
    <van-loading v-if="loading" :size="18"/>
    <van-icon v-else :name="$publicPath('/images/remove.png')" size="18" @click.stop.prevent="removeFromCart"/>
  </div>
</template>

<script setup>
const props = defineProps({
  product: {
    type: Object,
    default: () => {
    }
  },
  loading: {
    type: Boolean,
    default: false
  }
})

const emit = defineEmits(['remove'])

const removeFromCart = () => {
  emit('remove', {key: props.product?.key})
}
</script>