import {showNotify, closeNotify} from "vant";
import {NOTIFICATION_ERROR_TYPES} from "@/constants";
import {toast} from 'vue3-toastify';

export const showNotification = ({messages = [], type = NOTIFICATION_ERROR_TYPES.ERROR, autoClose = 3000} = '') => {
    const app = useNuxtApp();
    const isDesktop = app.$device.isDesktop;
    const locale = app.$i18n.locale;

    if (!isDesktop) {
        const notifyType = type === NOTIFICATION_ERROR_TYPES.ERROR ? 'danger' : type;
        showNotify({
            type: notifyType,
            message: Array.isArray(messages) ? messages.join('') : messages,
            duration: autoClose
        });
    } else {
        if (Array.isArray(messages)) {
            for (let message of messages) {
                showToast(message, type, locale.value, autoClose);
            }
        } else {
            showToast(messages, type, locale.value, autoClose);
        }
    }
};

export const hideNotifications = () => {
    closeNotify()
    toast.clearAll()
}

const showToast = (message, type, locale, autoClose) => {
    toast(message, {
        type,
        theme: "colored",
        autoClose,
        hideProgressBar: true,
        rtl: locale === 'ar',
        position: locale === 'ar' ? toast.POSITION.TOP_LEFT : toast.POSITION.TOP_RIGHT,
    });
};
