export default () => {
    
    const {locale, t} = useI18n()

    return {
        '@context': 'https://schema.org',
        '@type': 'Corporation',
        name: t('niceone'),
        alternateName: t('niceone'),
        url: `https://niceonesa.com/${locale.value}`,
        email: 'mailto:cs@niceonesa.com',
        telephone: '0092 0033 385',
        logo: 'https://cdn.niceonesa.com/logs/logo.png',
        sameAs: [
            'https://www.facebook.com/pg/niceonesa/about/',
            'https://twitter.com/NICEONESA',
            `https://www.instagram.com/niceonesa/?hl=en`,
            'https://www.youtube.com/channel/UCu8c7LeOLpt1pPICrkHBKiw',
            'https://www.linkedin.com/company/niceonesa/'
        ]
    }
}