export default () => {

    const {locale} = useI18n()

    return {
        '@context': 'https://schema.org',
        '@type': 'WebSite',
        url: 'https://niceonesa.com',
        potentialAction: {
            '@type': 'SearchAction',
            target: `https://niceonesa.com/${locale.value}/search/?q={search_term_string}`,
            'query-input': 'required name=search_term_string'
        }
    }
}
