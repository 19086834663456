<template>
  <div class="flex-center bg-anti-flash-white rounded-full z-1 absolute bottom-0 ltr:right-0 rtl:left-0">
    <template v-if="outOfStock">
      <favourite-icon :id="product[idKey]" :product="product" class="flex-center h-[36px] min-w-[36px]">
        <template #in-active>
          <van-icon :name="$publicPath('/images/cart-buttons/notify.svg')"
                    class="cursor-pointer"
                    size="20"/>
        </template>
        <template #active>
          <div class="bg-anti-flash-white rounded-full h-full flex-center text-xs font-medium px-2">
            {{ $t("product.btn.youll_be_notified") }}
          </div>
        </template>
      </favourite-icon>
    </template>
    <div v-if="!hasOptions && !outOfStock && !loading" class="flex-center !h-[36px] !w-[36px]"
         @click.stop.prevent="addToCart()">
      <niceone-image
          :src="$publicPath('/images/cart-buttons/add.svg')"
          loading="eager"
          width="24"/>
    </div>
    <template v-if="hasOptions && !outOfStock">
      <div class="!h-[36px] !w-[36px] flex-center" @click="openProduct">
        <niceone-image :src="$publicPath('/images/cart-buttons/options.svg')" width="24"/>
      </div>
    </template>
    <hover v-if="(getCartProductCount && !hasOptions) || loading" ref="hoverComponentRef" #default="{hover}"
           :close-delay="3000"
           :open-delay="100">
      <div
          :style="{width: (hover && getCartProductCount) || loading ? '90px' : '36px' }"
          class="flex-center rounded-full !h-[36px] absolute ltr:right-0 rtl:left-0 bottom-0 expand-transition bg-anti-flash-white"
          @click.stop.prevent>
        <div v-if="!hover && !loading" :style="{ color: '#372E4C' }" class="text-base font-niceone-medium">
          {{ getCartProductCount }}
        </div>
        <div v-if="hover || loading" class="flex items-center justify-between relative w-full px-2">
          <niceone-image v-if="Number(getCartProductCount) === 1" :src="$publicPath('/images/cart-buttons/delete.svg')"
                         loading="eager"
                         width="24"
                         @click="onRemove()"/>
          <niceone-image v-else :src="$publicPath('/images/cart-buttons/subtract.svg')" loading="eager" width="24"
                         @click="onCounterUpdate(false)"/>
          <div v-if="!loading" class="text-primary">{{ getCartProductCount }}</div>
          <van-loading v-else color="#222" size="18"/>
          <niceone-image :src="$publicPath('/images/cart-buttons/plus.svg')"
                         loading="eager"
                         width="24"
                         @click="onCounterUpdate(true)"/>
        </div>
      </div>
    </hover>
  </div>
</template>

<script setup>
import {addCartQuery, removeCartQuery, updateCartQuery} from "@/plugins/API/cart"
import {useCartStore} from '@/store/cart'
import hover from "@/components/common/Hover"
import {TRACKERS} from "@/constants/events";
import FavouriteIcon from "@/components/product/FavouriteIcon";

const props = defineProps({
  product: {
    type: Object,
    default: () => {
    }
  },
  fontSize: {
    type: Number,
    default: 12
  },
  iconSize: {
    type: Number,
    default: 10
  },
  padding: {
    type: Number,
    default: 12
  },
  height: {
    type: Number,
    default: 40
  },
  quantityKey: {
    type: String,
    default: 'quantity'
  },
  idKey: {
    type: String,
    default: 'id'
  },
  link: {
    type: String,
    default: ""
  }
})

const productId = computed(() => props.product?.id || props.product?.product_id)

const hoverComponentRef = ref(null)
const setHovered = (value) => hoverComponentRef.value?.setHovered(value)

// Add cart mutation
const {isLoading, mutateAsync} = addCartQuery()
const addToCart = () => {
  const {option_detail = null} = props.product
  const option = !!option_detail && {
    [option_detail?.product_option_id]: option_detail?.product_option_value_id,
  };

  mutateAsync({id: productId.value, option}).then(() => {
    // For Algolia convertedObjectIDsAfterSearch
    if (props.product?.queryID) track(TRACKERS.ALGOLIA_ADD_TO_CART, props.product)

    track(TRACKERS.ADD_TO_CART, {product: props.product})

    // We want to set the hovered for mobile version only
    if (!useNuxtApp().$device.isDesktop) setHovered(true)
  })
}

// Update cart mutation
const {isLoading: updateLoading, mutateAsync: updateMutateAsync} = updateCartQuery()
const onCounterUpdate = (isIncrement = false) => {
  const key = getCartProductKeyById(productId.value)
  const count = Number(getCartProductCount.value)
  const quantity = isIncrement ? count + 1 : count - 1
  setHovered(true)
  updateMutateAsync({key, quantity}).then(() => {
    setHovered(true)
    track(TRACKERS.INCREMENT_CART, {product: props.product})
  })
}

// Remove cart mutation
const {isLoading: removeLoading, mutateAsync: removeMutateAsync} = removeCartQuery()
const onRemove = () => {
  const key = getCartProductKeyById(productId.value)
  removeMutateAsync({key}).then(() => {
    track(TRACKERS.REMOVE_FROM_CART, {product: props.product})
  })
}

const hasOptions = computed(() => !!props?.product?.has_option)
const outOfStock = computed(() => !props?.product?.[props.quantityKey])

const {getCartProductCountById, getCartProductKeyById} = useCartStore()
const getCartProductCount = computed(() => getCartProductCountById(productId.value, props.product))

// We want to disabled add to cart button in these cases so clicking on the product will lead to the product detail page
const isBtnDisabled = computed(() => loading.value || hasOptions.value || getCartProductCount.value)
const loading = computed(() => isLoading.value || removeLoading.value || updateLoading.value)

const router = useRouter()
const openProduct = () => {
  router.push(useNuxtApp().$routerUrl(props.link))
}
</script>

<style>
.expand-transition {
  transition: width 0.3s ease-in-out 0s;
}
</style>