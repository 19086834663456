<template>
  <van-popup v-model:show="model" :close-icon-position="$i18n.locale === 'en' ? 'top-right' : 'top-left'"
             :position="!$device.isDesktop?'bottom':'center'"
             :style="{ maxWidth: !$device.isDesktop ? '100%' : '350px', minWidth:  !$device.isDesktop ? '100%':'350px', borderRadius: '10px' }"
             class="p-4" v-bind="$attrs"
             closeable @click-overlay.prevent="onClose" @click-close-icon.prevent="onClose">
    <div class="flex-center flex-col h-full py-3">
      <div class="font-bold text-lg mb-6">{{ $t('cart.alert_message') }}</div>
      <div class="flex-center gap-4">
        <van-button :loading="loading" class="sm:!h-10 !rounded-md !font-bold" type="primary"
                    @click.prevent="emit('delete')">{{
            $t('common.delete')
          }}
        </van-button>
        <van-button class="sm:!h-10 !rounded-md !font-bold" type="default" @click.prevent="emit('cancel')">
          {{ $t('common.cancel') }}
        </van-button>
      </div>
    </div>
  </van-popup>
</template>

<script setup>
const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false
  },
  loading: {
    type: Boolean,
    default: false
  },
});

const emit = defineEmits(['update:modelValue', 'cancel', 'delete'])

const model = computed({
  get() {
    return props.modelValue
  },
  set(val) {
    emit('update:modelValue', val)
  }
})
const onClose = () => {
  emit('cancel')
}
</script>

<style lang="scss" scoped>

</style>