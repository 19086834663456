<template>
  <div
      class="flex items-center gap-3 h-[48px] text-black text-[16px] group-hover:border-b-black border-b-3 border-white">
    <span><van-icon name="wap-nav" size="24"/></span>
    <span>{{ $t('header.category.all_categories') }}</span>
  </div>
  <div
      class="hidden group-hover:flex top-14 transition ease-in-out delay-[.3s] shadow-cus z absolute bg-[#f9f9f9] z-10"
  >
    <div
        v-for="(categories, level) in categoryList"
        :key="level"
        class="relative max-w-[225px] min-w-[225px] min-h-full"
    >
      <nuxt-link
          v-for="category in categories"
          :key="category.category_id"
          :to="$routerUrl(category.seo_url)"
          class="hover:bg-[#f4f4f4] flex items-center justify-between py-[11px] px-2"
          @click="onMenuClick(category)"
          @mouseover="hoverMainCategory(category, level)"
      >
        <div class="flex items-center gap-5">
          <div v-if="level === 0" class="w-[10%]">
            <niceone-image
                :alt="category.name"
                :src="category.image"
            />
          </div>
          <div class="text-sm text-black">
            {{ category.name }}
          </div>
        </div>
        <van-icon
            v-if="category.categories && category.categories.length"
            class="flip-icon" name="arrow"
        />
      </nuxt-link>
    </div>
  </div>
</template>
<script setup>
import {useCategoryStore} from "@/store/category";
import {track} from "@/composables/useTrackEvent";
import {TRACKERS} from "@/constants/events";

const categoryStore = useCategoryStore();
const categoryList = ref([categoryStore?.getCategories]);
const currentLevel = ref(0);
const hoverMainCategory = (category, level) => {
  if (currentLevel.value >= level) {
    categoryList.value.splice(level + 1, categoryList.value.length - 1);
  }
  currentLevel.value = level;

  if (!category.categories.length) {
    return categoryList.value.splice(level + 1, 1);
  }
  categoryList.value.push(category.categories);
};
const onMenuClick = (category) => {
  track(TRACKERS.HAM_BURGER_MENU, {
    eventLabel: category.name,
    eventAction: category.seo_url
  })
}
</script>
<style lang="scss" scoped></style>