<template>
  <div>
    <van-popup
        v-model:show="showCart"
        :position="$i18n.locale === 'en' ? 'right' : 'left'"
        :style="{ width: !$device.isDesktop ? '100%' : '500px', height: '100%' }"
        @click-overlay.prevent="onClose"
    >
      <simple-wrapper>
        <template #header>
          <van-nav-bar :title="$t('cart.shopping_cart')" @click-left="onClose">
            <template #left>
              <van-icon name="cross" size="22"/>
            </template>
            <template #right>
              <cart-share/>
            </template>
          </van-nav-bar>
        </template>

        <client-only>
          <full-page-loader :loading="loading"/>
          <div v-if="!loading" class="bg-[#f8f8f8] ">
            <cart-list/>
            <recommended-products/>
          </div>

          <cart-footer class="bottom-0" @close-sidebar="showCart = false"/>
        </client-only>
      </simple-wrapper>
    </van-popup>

  </div>
</template>

<script setup>
import CartFooter from "@/components/cart/Footer";
import RecommendedProducts from "@/components/cart/RecommendedProducts";
import {useCartStore} from '@/store/cart'
import {useEventBus} from "@vueuse/core";
import {EVENTS} from "@/constants/events";
import CartShare from "@/components/cart/Share";

const route = useRoute()
const router = useRouter()

const {t} = useI18n();
const cartStore = useCartStore()
const {loading, cart, showCart} = storeToRefs(cartStore)

onMounted(() => {
  if (!route.query?.cart) return

  // Open cart with the help of cart query param
  if (!useNuxtApp().$device.isDesktop) return navigateTo(useNuxtApp().$routerUrl('/cart'))

  router.replace(useNuxtApp().$routerUrl('/'))
  showCart.value = true
})

const {emit: emitDeleteItem} = useEventBus(EVENTS.DELETE_DIALOG);
const onClose = () => {
  showCart.value = false
  emitDeleteItem()
}
</script>

<style lang="scss" scoped>

</style>