<template>
  <auth-layout
      :loading="isLoading"
      :submi-buttont-name="$t('common.reset') "
      @submit="onSubmit"
  >
    <template #title>{{ $t('auth.reset_password') }}</template>

    <div class="rounded-md bg-white">
      <niceone-field
          v-model="email"
          :placeholder="$t('auth.email')"
          class="rounded-md"
          type="email"
      ></niceone-field>
    </div>

    <template #actions>
      <div
          class="flex items-center mb-3"
          @click="authStore.changeType(AUTH_TYPES.LOGIN_EMAIL)"
      >
        <van-icon :name="$publicPath('/images/auth/email.svg')" size="20"/>
        <div class="mx-2">{{ $t('auth.login_with_email') }}</div>
      </div>
      <div class="flex items-center mb-3" @click="onClickRegister">
        <van-icon :name="$publicPath('/images/auth/profile.svg')" size="20"/>
        <div class="mx-2">{{ $t('auth.create_new_account') }}</div>
      </div>
    </template>
  </auth-layout>
</template>

<script setup>
import AuthLayout from "@/components/AuthLayout";
import {forgot} from "@/plugins/API/auth";
import {AUTH_TYPES, NOTIFICATION_ERROR_TYPES} from "@/constants";
import {useAuthStore} from "@/store/auth";
import {track} from "@/composables/useTrackEvent";
import {TRACKERS} from "@/constants/events";

const authStore = useAuthStore();
const {t} = useI18n()
const email = ref("");

const {isLoading, mutateAsync} = forgot();
const onSubmit = () => {
  if (isLoading.value) return;
  mutateAsync({email: email.value}).then((response) => {
    if (!response) return;
    showNotification({
      type: NOTIFICATION_ERROR_TYPES.SUCCESS,
      messages: t('messages.reset_password_link_have_been_sent_to_your_email_a'),
    });
    authStore.resetType();
  });
};
const onClickRegister = () => {
  authStore.changeType(AUTH_TYPES.REGISTER)
  track(TRACKERS.REGISTER_INITIATE)
}
</script>
