import {EVENTS} from "~/constants/events";

const {emit: triggerShareOn} = useEventBus(EVENTS.SHARE_ON)
export const useShareLink = () => {

    const share = (url = '') => {

        const shareData = {
            url,
            text:url
        }

        if (!window.navigator.share || window.navigator.share === undefined) {
            triggerShareOn({data: shareData})
        } else {
           navigator.share(shareData)
        }

    }
    return {
        share
    }

}