import {getFormattedWebsitePath} from "@/utilities";
import useCustomConfig from "@/composables/useEnvConfig";

export default () => {

    const {t} = useI18n()

    const i18nHead = useLocaleHead({
        addSeoAttributes: {
            canonicalQueries: ['q', 'page', 'filters', 'manufacturers', 'sort']
        }
    })

    const route = useRoute()

    const currentYear = new Date().getFullYear()

    const config = useCustomConfig()
    const isProduction = config.public.APP_ENV === 'production'

    return {
        titleTemplate: (title) => `${title} | ${t('seo.niceone_ksa')}`,
        title: t('seo.title'),
        htmlAttrs: {
            lang: i18nHead.value.htmlAttrs?.lang,
            dir: i18nHead.value.htmlAttrs?.lang === 'ar' ? 'rtl' : 'ltr'
        },
        meta: [
            {charset: 'utf-8'},
            {name: 'viewport', content: 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0'},
            {name: 'apple-itunes-app', content: 'app-id=1271754138'},
            ...getCommonMeta(route.name, t),
            {
                name: 'rating',
                content: 'General'
            },
            {
                name: 'Classification',
                content: 'Business'
            },
            {
                name: 'google-site-verification',
                content: 'n4PA0RbS_xQzTPuk_EdqjaSAX2aZQ-2TtrbOJPkH-Tw'
            },
            {
                name: 'copyright',
                content: `Copyright © 2016 – ${currentYear} NICE ONE || all right reserved`
            },
            {name: 'robots', content: () => getRobotsMeta(route.name, isProduction)},
            ...(i18nHead.value.meta || [])
        ],
        link: () => [...(i18nHead.value.link || [])]
    }
}

const getCommonMeta = (routeName, t) => {
    const notAllowedRoutes = ['slugs', 'product-details'];

    if (notAllowedRoutes.includes(routeName?.split('_')[0])) return [];

    return [
        {
            name: 'description',
            content: t('seo.description')
        },
        {
            name: 'og:title',
            content: 'نايس ون'
        },
        {
            name: 'og:type',
            content: 'product'
        },
        {
            name: 'og:url',
            content: getFormattedWebsitePath('/')
        },
        {
            name: 'og:email',
            content: 'cs@niceonesa.com'
        },
        {
            name: 'og:phone_number',
            content: '0092 0033 385'
        },
        {
            name: 'og:site_name',
            content: 'نايس ون'
        },
    ];
};

const getRobotsMeta = (routeName, isProduction = true) => {
    const noIndex = 'noindex,nofollow'
    const index = 'index,follow'

    const robotsShouldIndexedRoutes = [
        'index',
        'brands',
        'slugs',
        'product-details'
    ]

    if (robotsShouldIndexedRoutes.includes(routeName?.split('_')[0]) && isProduction) {
        return index
    }

    return noIndex
}
