import {getNotificationsMutation, getOfferNotificationMutation} from "@/plugins/API/notifications";

export const useNotificationsStore = defineStore('notifications', () => {

    const offers = ref([])
    const totalOffers = ref(0)
    const offersLoaded = ref(false)
    const {isLoading: offerLoading, mutateAsync: offerMutateAsync} = getOfferNotificationMutation()
    const fetchOffers = ({page = 1}) => {
        offerMutateAsync({page}).then(({data}) => {
            offers.value = [...offers.value, ...data.offers]
            totalOffers.value = data.totalOffers
            offersLoaded.value = true
        })
    }

    const messages = ref([])
    const totalMessages = ref(0)
    const messagesLoaded = ref(false)
    const {isLoading: messagesLoading, mutateAsync: messageMutateAsync} = getNotificationsMutation()
    const fetchMessages = ({page = 1}) => {
        messageMutateAsync({page}).then(({data}) => {
            messages.value = [...messages.value, ...data.notifications]
            totalMessages.value = data.total_count
            messagesLoaded.value = true
        })
    }

    const loading = computed(() => offerLoading.value || messagesLoading.value)

    const $reset = () => {
        offers.value = []
        totalOffers.value = 0
        offersLoaded.value = false
        messages.value = []
        totalMessages.value = 0
        messagesLoaded.value = false
    }

    return {
        offers,
        totalOffers,
        offersLoaded,
        fetchOffers,
        messages,
        totalMessages,
        messagesLoaded,
        fetchMessages,
        loading,
        $reset
    }
})