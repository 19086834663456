<template>
  <div :class="small ? 'h-[24px] px-2' : 'h-[36px] px-3'" :style="{background: getBadgeColor}"
       class="flex-center rounded-full">
    <niceone-image :src="$publicPath('/images/loyalty/badge/icon.svg')" :width="small ? 18 : 24" class="me-2"/>
    <div :class="small ? 'text-base' : 'text-lg'" class=" mb-[2px] text-white">{{ getBadgeName }}</div>
  </div>
</template>

<script setup>
import {LOYALTY_TYPES} from "@/constants";

const props = defineProps({
  type: {
    type: Number,
    default: 1
  },
  small: {
    type: Boolean,
    default: false
  }
})

const getBadgeName = computed(() => {
  switch (props.type) {
    case LOYALTY_TYPES.SILVER: {
      return 'Silver'
    }
    case LOYALTY_TYPES.GOLD: {
      return 'Gold'
    }
    case LOYALTY_TYPES.PLATINUM: {
      return 'Platinum'
    }
  }
})

const getBadgeColor = computed(() => {
  switch (props.type) {
    case LOYALTY_TYPES.SILVER: {
      return 'linear-gradient(90deg, #757575 8.47%, #B5B5B5 112.72%), rgba(231, 231, 231, 0.50)'
    }
    case LOYALTY_TYPES.GOLD: {
      return 'linear-gradient(90deg, rgba(128, 92, 23, 0.20) -3%, rgba(250, 250, 250, 0.00) 99.68%), #CE9B38';
    }
    case LOYALTY_TYPES.PLATINUM: {
      return '#000714'
    }
  }
})
</script>

<style scoped>

</style>