<template>
  <div v-if="memberShip?.tier_type" :style="getCardStyle" class="rounded-md p-3">

    <!-- Show on boarding design -->
    <div v-if="hasOnboarding" class="flex items-center" @click="hideOnboarding">
      <niceone-image :src="$publicPath('/images/loyalty/monogram.svg')"/>
      <div class="ms-3">
        <div class="font-niceone-medium text-base">{{ memberShip?.onboarding_title }}</div>
        <div>{{ memberShip?.onboarding_message }} <span><van-icon class="flip-icon" name="arrow"/></span></div>
      </div>
    </div>

    <!-- Show normal loyalty card design -->
    <div v-else>
      <div v-if="!minimize" class="flex justify-between">
        <div class="text-base">{{ memberShip?.membership_title }}</div>
        <member-ship-badge :type="memberShip?.tier_type"/>
      </div>
      <div class="flex justify-between pb-1">
        <div class="text-lg">
          <span :class="minimize ? 'text-2xl font-bold tracking-tighter' : 'text-6xl font-bold tracking-tighter'">
            {{ memberShip?.points_balance }}
          </span>
          <span v-if="memberShip?.tier_type !== LOYALTY_TYPES.PLATINUM"> / </span>
          <span v-if="memberShip?.tier_type !== LOYALTY_TYPES.PLATINUM"
                :class="minimize ? 'text-base' : 'text-2xl'">{{ memberShip?.points_threshold }}</span>
          <span class="ms-1">{{ $t('common.points') }}</span>
        </div>
        <div class="flex items-center gap-1">
          <member-ship-badge v-if="minimize" :type="memberShip?.tier_type" small/>
          <van-icon v-if="showArrow" class="flip-icon" name="arrow" size="18"/>
        </div>
      </div>
      <div v-if="memberShip?.points_calculation_message" class="text-sm mb-3">
        {{ memberShip?.points_calculation_message }}
      </div>
      <div v-if="!minimize" class="mb-2">
        <van-divider/>
      </div>
      <div class="mb-2">{{ memberShip?.next_tier_message }}</div>
      <van-progress :class="minimize ? 'mb-0' : 'mb-2'" :color="getProgressColor" :percentage="getPercentage"
                    :show-pivot="false" stroke-width="6"/>
      <div v-if="!minimize" v-html="memberShip?.next_tier_warning_info"></div>
    </div>

  </div>
</template>

<script setup>
import {LOYALTY_TYPES} from "@/constants";
import MemberShipBadge from "@/components/loyalty/Badge"
import {useAuthStore} from "~/store/auth";
import {storeToRefs} from "pinia";

const props = defineProps({
  memberShip: {
    type: Object,
    default: () => {
    }
  },
  minimize: {
    type: Boolean,
    default: false
  },
  showArrow: {
    type: Boolean,
    default: false
  }
})

const getPercentage = computed(() => {
  return Math.min((props.memberShip?.points_balance / props.memberShip?.points_threshold) * 100, 100)
})

const getCardStyle = computed(() => {

  if (hasOnboarding.value) return {background: '#D2D2D2', color: '#222'}

  switch (props.memberShip?.tier_type) {
    case LOYALTY_TYPES.SILVER: {
      return {background: '#D2D2D2', color: '#222'}
    }
    case LOYALTY_TYPES.GOLD: {
      return {background: '#DAB162', color: '#222'}
    }
    case LOYALTY_TYPES.PLATINUM: {
      return {background: '#272828', color: '#fff'}
    }
  }
})

const getProgressColor = computed(() => {
  switch (props.memberShip?.tier_type) {
    case LOYALTY_TYPES.PLATINUM: {
      return '#DAB162'
    }
    default: {
      return "#222"
    }
  }
})

const authStore = useAuthStore()
const {user} = storeToRefs(authStore)

const hideLoyaltyOnboarding = useCookie(`_hide_loyalty_onboarding_${user.value?.customer_id}`)
const hasOnboarding = computed(() => {
  return !hideLoyaltyOnboarding.value && props.memberShip?.points_balance === 0 && props.memberShip?.onboarding_title
})

const hideOnboarding = () => {
  hideLoyaltyOnboarding.value = true
}
</script>
