<template>
  <div>
    <div class="flex-center rounded border">
      <van-icon class="px-[6px] py-2 cursor-pointer z-1" name="minus" size="12"
                @click.stop.prevent="addToCart(false)"></van-icon>
      <div class="min-w-[36px] flex-center">
        <van-loading v-if="loading" :size="18"/>
        <span v-else>{{ quantity }}</span>
      </div>
      <van-icon class="px-[6px] py-2 cursor-pointer" name="plus" size="12"
                @click.stop.prevent="addToCart(true)"></van-icon>
    </div>
    <delete-popup v-model="showDeletePopup" :loading="loading" @cancel="onCancel" @delete="onDelete"/>
  </div>
</template>

<script setup>
import DeletePopup from "@/components/cart/DeletePopup.vue";
import {useEventBus} from "@vueuse/core/index";
import {EVENTS} from "~/constants/events";

const props = defineProps({
  product: {
    type: Object,
    default: () => {
    }
  },
  loading: {
    type: Boolean,
    default: false
  },
  itemKey: {
    type: String,
    default: "key"
  }
})
const showDeletePopup = ref(false)
const emit = defineEmits(['update'])
const {on} = useEventBus(EVENTS.DELETE_DIALOG)

on(() => {
  onCancel()
});
const quantity = computed(() => Number(props?.product?.quantity || 0))
const addToCart = (isIncrement = false) => {
  const qty = isIncrement ? quantity.value + 1 : quantity.value - 1
  if (qty < 1) {
    showDeletePopup.value = true
  } else {
    onAddToCart(qty)
  }
}
const onAddToCart = (qty) => {
  emit('update', {key: props.product[props.itemKey], quantity: qty})
}
const onDelete = (isIncrement = false) => {
  const qty = isIncrement ? quantity.value + 1 : quantity.value - 1
  onAddToCart(qty)
  onCancel()
}
const onCancel = () => {
  showDeletePopup.value = false
}
</script>