<template>
  <div ref="hoverRef" class="w-full" v-bind="$attrs">
    <slot :hover="isHovered"/>
  </div>
</template>

<script setup>
import {useElementHover} from '@vueuse/core'
import {onUnmounted, watch} from "vue";

const {closeDelay, openDelay} = defineProps({
  closeDelay: {
    type: Number,
    default: 0
  },
  openDelay: {
    type: Number,
    default: 0
  }
})

const hoverRef = ref(null)
const isHovered = useElementHover(hoverRef, {delayLeave: closeDelay, delayEnter: openDelay})

function setHovered(value) {
  isHovered.value = value
  // Call the function to handle automatic closing whenever isHovered changes
  handleAutoClose()
}

let timeoutId

// Function to handle automatic closing
function handleAutoClose() {

  // For desktop devices we don't need auto close
  if (useNuxtApp().$device.isDesktop) return

  // If isHovered becomes true, set timeout to reset it to false after closeDelay
  if (isHovered.value) {
    clearTimeout(timeoutId)
    timeoutId = setTimeout(() => {
      isHovered.value = false
    }, closeDelay)
  }
}

// Watch isHovered changes and call the function to handle automatic closing
watch(isHovered, () => {
  handleAutoClose()
})

onUnmounted(() => {
  // Cleanup timeout on component unmount to avoid memory leaks
  clearTimeout(timeoutId)
})

defineExpose({setHovered})
</script>
