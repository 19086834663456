<template>
  <auth-layout
      :loading="isLoading"
      :submi-buttont-name="$t('auth.login')"
      @submit="phoneLogin"
  >
    <template #title>{{ $t('auth.hello_there_login') }}</template>
    <mobile-phone-field v-model="phone"/>

    <template #actions>
      <div
          class="flex cursor-pointer items-center mb-3"
          @click="authStore.changeType(AUTH_TYPES.LOGIN_EMAIL)"
      >
        <van-icon :name="$publicPath('/images/auth/email.svg')" size="20"/>
        <div class="mx-2">{{ $t('auth.login_with_email') }}</div>
      </div>
      <div
          class="flex cursor-pointer items-center mb-3"
          @click="onClickRegister"
      >
        <van-icon :name="$publicPath('/images/auth/profile.svg')" size="20"/>
        <div class="mx-2">{{ $t('auth.create_new_account') }}</div>
      </div>
    </template>
  </auth-layout>
</template>

<script setup>
import MobilePhoneField from "@/components/form/MobilePhoneField";
import AuthLayout from "@/components/AuthLayout";
import {loginPhone} from "@/plugins/API/auth";
import {useEventBus} from "@vueuse/core";
import {AUTH_TYPES} from "@/constants";
import {EVENTS, TRACKERS} from "@/constants/events";
import {useAuthStore} from "@/store/auth";
import {track} from "@/composables/useTrackEvent";

const authStore = useAuthStore();

const {emit} = useEventBus(EVENTS.VERIFY_DIALOG);

const phone = ref("");

const {isLoading, mutateAsync} = loginPhone();
const phoneLogin = () => {
  if (isLoading.value) return;
  mutateAsync({telephone: phone.value}).then((response) => {
    if (!response) return;
    track(TRACKERS.LOGIN_SUCCESS, 'Email');
    emit({phone: phone.value});
  }).catch((error) => {
    track(TRACKERS.LOGIN_FAIL, error)
  });
};
const onClickRegister = () => {
  authStore.changeType(AUTH_TYPES.REGISTER)
  track(TRACKERS.REGISTER_INITIATE)
}
</script>
