export const COOKIE_KEYS = {
    USER_TOKEN: '_token',
    CURRENCY: '_currency',
    SESSION: '_session',
    PENDING_ROUTE: '_pending_route',
    SMART_BANNER: '_hide_smart_banner',
    PROFILE_BANNER: '_hide_profile_banner',
    REWARD_CODE: '_reward_code',
    CUSTOMER_GROUP_ID: 'x-customer-group-id',
    COOKIE_CONSENT: '_hide_cookie_consent'
}