<template>
  <div>
    <van-popup
        v-model:show="model"
        :position="$i18n.locale === 'en' ? 'right' : 'left'"
        :style="{ width: !$device.isDesktop ? '100%' : '500px', height: '100%' }"
    >
      <notifications>
        <template #icon>
          <van-icon name="cross" size="22" @click="model=false"/>
        </template>
      </notifications>
    </van-popup>
  </div>
</template>

<script setup>
import Notifications from "@/components/notifications/index";
import {useNotificationsStore} from "@/store/notifications";

const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false
  }
})
const notificationStore = useNotificationsStore()
onUnmounted(() => {
  notificationStore.$reset()
})
const emit = defineEmits(['update:modelValue'])

const model = computed({
  get() {
    return props.modelValue
  },
  set(val) {
    emit('update:modelValue', val)
  }
})
const onClose = () => {
  emit('update:modelValue', false)
}
</script>

<style lang="scss" scoped>

</style>