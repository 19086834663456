import {useMutation, useQueryClient} from "@tanstack/vue-query";
import {useAuthStore} from "@/store/auth";
import {hideNotifications} from "@/composables/useNotification";
import {COOKIE_KEYS} from "@/constants/cookie";

const setCustomerGroupId = (id) => {
    useCookie(COOKIE_KEYS.CUSTOMER_GROUP_ID).value = id
    useNuxtApp().$axios.defaults.headers[COOKIE_KEYS.CUSTOMER_GROUP_ID] = id;
}

export function loginPhone() {
    return useMutation(({telephone = ''}) => {

            // Hide all notifications before sending new request
            hideNotifications()

            return useNuxtApp().$axios.post("?route=rest/login/login", {telephone}, {
                autoClose: 10000
            });
        }
    );
}

export function loginEmail() {
    const queryClient = useQueryClient();
    return useMutation(({email = '', password = ''}) => {

            // Hide all notifications before sending new request
            hideNotifications()

            return useNuxtApp().$axios.post("?route=rest/login/login", {
                email,
                password
            }, {
                autoClose: 10000,
            });
        },

        {
            onSuccess: ({data}) => {
                useNuxtApp().$axios.defaults.headers[
                    "authorization"
                    ] = `Bearer ${data.authToken}`;
                setCustomerGroupId(data?.customer_group_id)
                queryClient.invalidateQueries(["cart"]);
                queryClient.invalidateQueries(["wishlist"]);
                queryClient.invalidateQueries(["account"]);
            },
        }
    );
}

export function verify() {
    const queryClient = useQueryClient();
    return useMutation(
        ({code, isRegister = false, gc_token = ""}) => {
            const params = {
                code,
                gc_token
            };
            return isRegister ? useNuxtApp().$axios.post("?route=rest/register/verifyphone", params, {
                headers: {
                    gc_token
                }
            }) : useNuxtApp().$axios.post("?route=rest/login/verifyphone", params);
        },
        {
            onSuccess: ({data}) => {
                useNuxtApp().$axios.defaults.headers[
                    "authorization"
                    ] = `Bearer ${data.authToken}`;
                setCustomerGroupId(data?.customer_group_id)
                queryClient.invalidateQueries(["cart"]);
                queryClient.invalidateQueries(["wishlist"]);
                queryClient.invalidateQueries(["account"]);
            },
        }
    );
}

export function forgot() {
    return useMutation(({email = ''}) => {
        return useNuxtApp().$axios.post("?route=rest/forgotten/forgotten", {email});
    });
}

export function resendVerificationMutation() {
    return useMutation(
        ({customer_id = "", isRegister = false, gc_token = ""}) => {
            return isRegister ? useNuxtApp().$axios.post("?route=rest/register/resendVerificationCode", {
                customer_id,
                gc_token
            }, {
                headers: {
                    gc_token
                }
            }) : useNuxtApp().$axios.post("?route=rest/login/resendVerificationCode");
        }
    );
}

export function register() {
    return useMutation(({
                            agree = 1,
                            password = '',
                            firstname,
                            lastname = '',
                            email = '',
                            gender = 1,
                            telephone = '',
                            gc_token = ''
                        }) => {

        // Hide all notification before sending new request
        hideNotifications()

        return useNuxtApp().$axios.post("?route=rest/register/register", {
            agree,
            password,
            confirm: password,
            firstname,
            lastname,
            email,
            gender,
            telephone,
            gc_token
        }, {
            autoClose: 10000,
            headers: {gc_token}
        });
    });
}

export function getUser() {
    return useMutation(() => {
        return useNuxtApp()
            .$axios.get("?route=rest/account/account")
            .then((response) => {
                return response?.data || {};
            });
    });
}

export function logoutUser() {
    const queryClient = useQueryClient();
    return useMutation(
        () => {
            return useNuxtApp().$axios.post("?route=rest/logout/logout");
        },
        {
            onSuccess: () => {
                const token = useCookie(COOKIE_KEYS.USER_TOKEN);
                const groupId = useCookie(COOKIE_KEYS.CUSTOMER_GROUP_ID)
                token.value = "";
                groupId.value = null;

                const authStore = useAuthStore()
                const {setUser} = authStore
                setUser(null)

                delete useNuxtApp().$axios.defaults.headers.authorization;
                delete useNuxtApp().$axios.defaults.headers[COOKIE_KEYS.CUSTOMER_GROUP_ID];

                queryClient.invalidateQueries(["cart"]);
                queryClient.invalidateQueries(["wishlist"]);
            },
        }
    );
}
