import {useQuery} from '@tanstack/vue-query';

export function getSearchTrends(options) {

    const {enabled = true} = options

    const queryKey = ['search-trends'];

    return useQuery(
        queryKey,
        () => {
            return useNuxtApp().$axios("?route=feed/rest_api/search_trends").then(response => response?.data || {})
        },
        enabled
    );
}

export function getSearchResults(params, options) {

    const {search = ''} = params
    const {enabled = true} = options

    const queryKey = ['search-results', search];

    return useQuery(
        queryKey,
        () => {
            return useNuxtApp().$axios(`?route=feed/rest_api/completeProductsNames&name=${search.value}`).then(response => response?.data || {})
        },
        enabled
    );
}