<template>
  <div class="flex flex-col space-y-2 px-4 py-3 bg-white rounded-lg">
    <order-product :product="product"/>
    <div class="flex justify-between py-3 items-center border-b border-b-[#e1e1e1] border-t border-t-[#e1e1e1]">
      <div class="flex gap-6 items-center">
        <span>{{ $t('product.reviews.add_review') }}</span>
        <van-rate
            v-model="rating"
            :size="18"
            color="#ffd21e"
            void-color="#eee"
            void-icon="star"
        />
      </div>
      <span class="text-sm font-niceone-bold">{{ rating }}/5</span>
    </div>
    <p v-if="loyalty" class="flex items-center gap-3">
      <span>{{ $t("product.reviews.your_review_of_the_product") }}</span>
      <span v-if="loyalty.points_for_review_text" class="bg-[#fff2f2] text-[#f6abb9] text-xs rounded-full p-2 py-1">{{
          loyalty.points_for_review_text
        }}</span>
    </p>
    <van-field
        v-model="comment"
        :placeholder="$t('common.your_comment')"
        autosize
        class="border-b md:border border-[#ced4da] rounded-md"
        maxlength="50"
        rows="2"
        show-word-limit
        type="textarea"
    />
    <p class="flex items-center gap-1">
      <span>{{ $t('product.reviews.i_attach_some_pictures') }}</span>
      <span v-if="loyalty.points_for_review_image"
            class="bg-[#fff2f2] text-[#f6abb9] text-xs rounded-full p-2 py-1">{{
          loyalty.points_for_review_image
        }}</span>
    </p>
    <van-uploader
        v-model="fileList"
        :upload-icon="$publicPath('/images/uploaderIcon.svg')"
        multiple
    >
      <template #preview-cover="{ file }">
        <div
            class="absolute bottom-0 box-border w-full p-1 text-white text-center bg-[rgba(0,0,0,0.3)] van-ellipsis"
        >
          {{ file.name }}
        </div>
      </template>
    </van-uploader>
    <van-button :disabled="loading || isCommentEmpty" :loading="loading" block class="!mt-1" type="primary"
                @click="submitReview">
      {{ $t('common.send') }}
    </van-button>
  </div>
</template>

<script setup>
import OrderProduct from '@/components/ratemodal/Product'
import {addOrderReviewMutation} from "@/plugins/API/review";
import {NOTIFICATION_ERROR_TYPES} from "@/constants";
import {track} from "@/composables/useTrackEvent";
import {TRACKERS} from "@/constants/events";
import {useIsEmpty} from "@/composables/useIsEmpty";
import {useAuthStore} from "@/store/auth";

const authStore = useAuthStore()
const {user} = storeToRefs(authStore)

const {isEmpty} = useIsEmpty()
const props = defineProps({
  product: {
    type: Object,
    default: {}
  },
  loyalty: {
    type: Object,
    default: {}
  }
})
const rating = ref(5);
const fileList = ref([]);
const comment = ref('');
const isCommentEmpty = computed(() => {
  return comment.value.trim() === '';
});
const emit = defineEmits(['removeProductFromListById'])
const {isLoading: loading, mutateAsync: mutateAsyncAddOrderReview} = addOrderReviewMutation();
const submitReview = () => {
  const data = new FormData();
  data.append('order_product_id', props.product.order_product_id)
  data.append('text', comment.value)
  data.append('rating', rating.value)
  fileList.value.forEach((attachment, index) => {
    data.append(`attachment[${index}]`, attachment);
  });
  mutateAsyncAddOrderReview(data).then(({data}) => {
    showNotification({
      type: NOTIFICATION_ERROR_TYPES.SUCCESS, messages: data.message
    });
    resetForm()
    emit('removeProductFromListById', props.product.product_id)
    track(TRACKERS.ITEM_REVIEWED, {
      attachment_count: fileList.value.length,
      has_description: isCommentEmpty.value,
      loyalty_enabled: !isEmpty(props.loyalty),
      order_id: user.value.order_product_reviews_pending[0]?.order_id,
      product: props.product,
      rating_value: rating.value
    });
  }).catch(() => {
  })
};
const resetForm = () => {
  rating.value = 5
  comment.value = ''
  fileList.value = []
}
</script>

<style lang="scss" scoped>
</style>