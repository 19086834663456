<template>
  <div class="flex flex-col items-center justify-between relative">
    <niceone-image :src="$publicPath('/images/error.png')" class="my-8" width="185"/>
    <div class="error-text">{{ error?.statusCode ? error?.statusCode : 404 }}</div>
    <h1 class="font-niceone-medium text-base">{{ $t('error_page.title') }}</h1>
    <div class="mb-4">{{ $t('error_page.description') }}</div>
    <van-button type="primary" @click="handleError">{{ $t('error_page.home') }}</van-button>
  </div>
</template>

<script setup>

const props = defineProps({
  error: {
    type: Object,
    default: () => {
    }
  }
})

const handleError = () => clearError({redirect: useNuxtApp().$routerUrl('/')})
</script>

<style scoped>
.error-text {
  position: absolute;
  top: 58%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: .21;
  font-size: 150px;
  font-weight: 600;
  color: rgba(149, 119, 188, .51);
}
</style>