<template>
  <Overlay v-if="overlayPopup" @click="overlayPopup = false"/>

  <div class="bg-white z-4">
    <div class="custom-container px-4">
      <div
          class="flex justify-between items-center pt-1 pb-1 md:pb-0 md:pt-4"
      >
        <div class="sm:hidden flex items-center">
          <button class="outline-none mobile-menu-button">
            <niceone-image :src="$publicPath('/images/menubtn.svg')"/>
          </button>
        </div>
        <div class="flex items-center w-full">
          <!-- Website Logo -->
          <nuxt-link :to="$routerUrl('/')" class="hidden sm:flex items-center" @click="onLogoClick">
            <niceone-image
                :src="
                    locale === 'ar'
                      ? $publicPath('/images/rtl_logo.svg')
                      : $publicPath('/images/ltr_logo.svg')
                  "
                class="w-[170px]"
            />
          </nuxt-link>
          <div class="w-full mx-auto flex-[0.9] relative">
            <MainSearch/>
          </div>
        </div>
        <div class="hidden md:flex items-center gap-2 flex-shrink-0">
          <niceone-image :src="$publicPath('/images/medal.svg')" width="26"/>
          <div class="hidden lg:flex text-[18px]">{{ t("home.introduction") }}</div>
        </div>
      </div>
    </div>
  </div>

  <div class="sticky bg-white border-b top-0 z-3">
    <div class="custom-container px-4 relative">
      <div class="flex justify-between items-center mx-auto">
        <div class="relative">
          <div class="flex items-center justify-center pt-[7px] gap-[15px]">
            <div v-if="minimized">
              <nuxt-link :to="$routerUrl('/')" class="flex items-center">
                <niceone-image :src="$publicPath('/images/logo-black.png')" height="40px" width="40px"/>
              </nuxt-link>
            </div>
            <div class="flex gap-2 group" @mouseleave="overlayPopup = false" @mouseover="overlayPopup = true">
              <Categories/>
            </div>
            <div class="w-1 border-r-[1px] border-[#efefef] h-6 hidden lg:block"></div>
            <div v-for="(category, index) in categoriesList" :key="index"
                 class="hidden lg:flex gap-4 items-center group">
              <nuxt-link :to="$routerUrl(category.seo_url)"
                         class="flex items-center h-[48px] text-black text-[16px] group-hover:border-b-black border-b-3 border-white"
                         @click="onNavigationMenuClick(category)">
                <span>{{ category.name }}</span>
              </nuxt-link>
              <div v-if="index < categoriesList.length - 1" class="w-1 border-r-[1px] border-[#efefef] h-6"></div>
            </div>
            <div class="w-1 border-r-[1px] border-[#efefef] h-6 hidden sm:block"></div>
            <div class="flex gap-2 items-center group hidden sm:block">
              <nuxt-link :to="$routerUrl('/brands')"
                         class="flex items-center gap-3 h-[48px] text-black text-[16px] group-hover:border-b-black border-b-3 border-white"
                         @click="onNavigationMenuClick">
                <span>{{ $t('menu.brands') }}</span>
              </nuxt-link>
            </div>
          </div>
        </div>
        <div
            :class="[minimized ? 'flex-[0.9]' : 'hidden']"
            class="relative w-full mx-auto"
        >
          <MainSearch/>
        </div>
        <ul class="flex items-center justify-center pt-[7px] gap-6">
          <li
              class="flex items-center justify-center group hover:border-b-black border-b-3 border-white"
              @mouseleave="overlayPopup = false"
              @mouseover="overlayPopup = true"
          >
            <Language/>
          </li>
          <li
              class="flex items-center justify-center group hover:border-b-black border-b-3 border-white"
              @mouseleave="overlayPopup = false"
              @mouseover="overlayPopup = true"
          >
            <Country/>
          </li>
          <li
              class="flex items-center group hover:border-b-black border-b-3 border-white"
              @mouseleave="overlayPopup = false"
              @mouseover="overlayPopup = true"
          >
            <Profile @hideOverlay="overlayPopup = false"/>
          </li>
          <li class="flex items-center group hover:border-b-black border-b-3 border-white">
            <div class="flex items-center justify-center h-[48px] text-[16px] cursor-pointer"
                 @click="showNotifications = true">
              <van-badge :content="0" :max="99" :position="$i18n.locale === 'en' ? 'top-right' : 'top-left'"
                         :show-zero="false">
                <niceone-image :src="$publicPath('/images/notification.svg')" width="18"/>
              </van-badge>
            </div>
          </li>
          <li
              class="flex items-center group hover:border-b-black border-b-3 border-white"
          >
            <div
                class="flex items-center justify-center h-[48px] text-[16px] cursor-pointer"
                @click="showCart = true"
            >
                    <span class="relative">
                      <van-badge :content="count" :max="99" :position="$i18n.locale === 'en' ? 'top-right' : 'top-left'"
                                 :show-zero="false">
                        <niceone-image
                            :src="$publicPath('/images/icon-cart.svg')"
                            width="18"
                        />
                      </van-badge>
                    </span>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>

  <notification-sidebar v-model="showNotifications"/>
</template>
<script setup>
import Overlay from "@/components/common/Overlay";
import {useCategoryStore} from "@/store/category";
import Categories from "@/components/common/Categories";
import Language from "@/components/common/Langauge";
import Country from "@/components/common/Country";
import Profile from "@/components/common/Profile";
import MainSearch from "@/components/home/MainSearchSection"
import NotificationSidebar from '@/components/sidebar/Notifications'
import {useCartStore} from '@/store/cart'
import {track} from "@/composables/useTrackEvent";
import {TRACKERS} from "@/constants/events";
import {useWindowScroll} from '@vueuse/core'

const {y: topPosition} = useWindowScroll()

const cartStore = useCartStore()
const categoryStore = useCategoryStore();
const categoriesList = computed(
    () => categoryStore?.categories.slice(0, 3) || []
);
const minimized = computed(() => topPosition.value > 70)
const {count, showCart} = storeToRefs(cartStore)
const {locale, t} = useI18n();
const overlayPopup = ref(false);
const showNotifications = ref(false);
const onLogoClick = () => {
  track(TRACKERS.LOGO_CLICK)
}
const onNavigationMenuClick = (category = null) => {
  track(TRACKERS.NAVIGATION_MENU, {
    eventLabel: category && category.name ? category.name : 'brand',
    eventAction: category && category.seo_url ? category.seo_url : ''
  });
}
</script>

<style lang="scss" scoped></style>