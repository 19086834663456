<template>
  <div class="px-4 text-center pt-2">
    <empty-layout v-if="!cartProducts?.length"
                  :description="$t('empty.add_some_products_in_cart_to_complete_your_order')"
                  :image="$publicPath('/images/placeholder/new-cart.svg')"
                  :title="$t('empty.your_shopping_bag_is_empty')">
      <template #default>
        <nuxt-link :to="$routerUrl('/')" @click="closeSideBar"
                   class="bg-black rounded-md block text-white text-md font-niceone-medium w-full text-center py-3 mt-5">
          {{ $t('common.shop_now') }}
        </nuxt-link>
      </template>
    </empty-layout>
  </div>

  <div v-if="cart?.Ad?.image_url" class="p-3">
    <niceone-image :src="cart?.Ad?.image_url" class="w-full rounded-lg"/>
  </div>
  <cart-item v-for="(product, index) in cartProducts" :key="`cart-item-${index}`" :index="index"
             :product="product"/>
</template>

<script setup>
import CartItem from '@/components/cart/Item';

import {useCartStore} from '@/store/cart'
import {storeToRefs} from 'pinia';

const cartStore = useCartStore()
const {cart, cartProducts, showCart} = storeToRefs(cartStore)
const closeSideBar = () => showCart.value = false
</script>