<template>
  <NuxtLayout>
    <SystemLoader/>
    <VitePwaManifest/>
    <NuxtPage/>
    <the-tabbar/>
    <verify/>
    <auth-sidebar/>
    <share-on/>

    <!-- Load these components only when the app is fully loaded -->
    <template v-if="appLoaded">
      <cart-sidebar/>
      <smart-banner-popup/>
      <rate-orders-modal/>
      <feedback-order-popup/>
      <cookie-consent-popup/>
    </template>

  </NuxtLayout>
</template>

<script setup>
import {useCategoryStore} from '@/store/category';
import {useCountryStore} from '@/store/countries'
import {useCartStore} from '@/store/cart'
import {useWishlistStore} from '@/store/wishlist'
import {useContentStore} from "@/store/content";
import Verify from '@/components/auth/Verify';
import SmartBannerPopup from '@/components/smart-banner/popup';
import '@vant/touch-emulator';
import AuthSidebar from "@/components/sidebar/Auth";
import 'vue3-toastify/dist/index.css'
import HomeJsonld from "@/jsonld/home";
import SearchJsonld from "@/jsonld/search";
import HomeHead from "@/head/home"
import {useCustomFetch} from "@/composables/useFetch";
import RateOrdersModal from "@/components/RateOrdersModal.vue";
import {track} from "@/composables/useTrackEvent";
import {TRACKERS} from "@/constants/events";
import FeedbackOrderPopup from "@/components/FeedbackOrderPopup.vue";
import SystemLoader from "@/components/common/SystemLoader.vue";
import {COOKIE_KEYS} from "@/constants/cookie";
import {useAuthStore} from "@/store/auth";
import {useApp} from "@/composables/useApp";
import {useConfigStore} from "@/plugins/API/config";
import CartSidebar from "@/components/sidebar/Cart";
import {onKeyStroke} from '@vueuse/core'
import {NOTIFICATION_ERROR_TYPES} from "@/constants";
import {APP_VERSION} from "@/constants/version";
import CookieConsentPopup from "@/components/common/CookieConsentPopup";

// Head schema
useHead({
  ...HomeHead()
})

// Json schemas
useJsonld(HomeJsonld())
useJsonld(SearchJsonld())
useCartStore()
useWishlistStore()
useContentStore()

const configStore = useConfigStore()
const {appLoaded} = storeToRefs(configStore)

const {onAppLoaded} = useApp();
onMounted(() => {
  track(TRACKERS.INITIALIZED)
  onAppLoaded()
})

const {data} = await useCustomFetch('?route=rest/v2/app/getContent')

const contentStore = useContentStore()
contentStore.setContent(data.value)
const categories = computed(() => data?.value?.categories || [])
const categoryStore = useCategoryStore()
categoryStore.addCategories(categories.value)

const countries = computed(() => data?.value?.countries || [])
const countryStore = useCountryStore()
countryStore.addCountries(countries.value)

const token = useCookie(COOKIE_KEYS.USER_TOKEN)
if (token.value) {
  const authStore = useAuthStore()
  const {data: userData} = await useCustomFetch('?route=rest/account/account')
  authStore.setUser(userData.value)
}

onKeyStroke('V', () => {
      showNotification({
        type: NOTIFICATION_ERROR_TYPES.SUCCESS, messages: `Version: ${APP_VERSION}`
      });
    }
)
</script>