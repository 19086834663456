import {useMutation} from "@tanstack/vue-query";

export function productReviewMutation() {
    return useMutation((data) => {
        return useNuxtApp().$axios.post("?route=rest/reviews/product_reviews", data);
    });
}

export function orderProductReviewMutation() {
    return useMutation((data) => {
        return useNuxtApp().$axios.post("?route=rest/reviews/order_reviews", data);
    });
}

export function likeReview() {
    return useMutation(
        (data) => {
            return useNuxtApp().$axios.post("?route=rest/reviews/like", data)
        }
    )
}

export function addComments() {
    return useMutation(
        (data) => {
            return useNuxtApp().$axios.post("?route=rest/reviews/comments", data)
        }
    )
}

export function getCommentsByReviewId() {
    return useMutation((review_id) => {
        return useNuxtApp().$axios.get("?route=rest/reviews/comments&review_id", {
            params: {review_id},
        });
    });
}

export function addOrderReviewMutation() {
    return useMutation((data) => {
        return useNuxtApp().$axios.post("?route=rest/reviews/order_reviews", data);
    });
}

export function getReviewPendingProducts() {
    return useMutation((id) => {
        return useNuxtApp().$axios.get("?route=rest/order/order_pending_products_review", {
            params: {id},
        });
    });
}

export function getEstimatedTime() {
    return useMutation((city_id) => {
        return useNuxtApp().$axios.get("?route=rest/v4/estimated_delivery", {
            params: {city_id},
        });
    });
}

export function getCities() {
    return useMutation(() => {
        return useNuxtApp().$axios.get("?route=rest/v4/estimated_delivery/cities_list");
    });
}
