<template>
  <div class="m-3">
    <full-page-loader v-if="page === 1" :loading="loading"/>
    <section-layout v-for="(item, index) in offers" :key="`offer-${index}`">
      <a :href="item?.redirect_url" class="w-100">
        <niceone-image :src="item?.image"/>
        <div class="mt-2">{{ item.title }}</div>
      </a>
    </section-layout>
    <empty-layout
        v-if="!loading && !offers.length"
        :image="$publicPath('/images/placeholder/offers.svg')"
        class="min-h-[600px]"
        :description="$t('empty.no_new_offers_available')"
        :title="$t('empty.offers_box_is_empty')"/>
    <infinite-load-more v-model="page" :items-length="offers?.length" :loading="loading" :total="totalOffers"/>
  </div>
</template>

<script setup>
import {useNotificationsStore} from "@/store/notifications";
import InfiniteLoadMore from "@/components/common/InfiniteLoadMore";

const notificationStore = useNotificationsStore()
const {loading, offers, totalOffers, offersLoaded} = storeToRefs(notificationStore)

const page = ref(1)

onMounted(() => {
  !offersLoaded.value && fetch()
})

const fetch = () => {
  notificationStore.fetchOffers({page: page.value})
}
</script>

<style scoped>

</style>