<template>
  <p id="tooltip-button-2" class="text-[13px] font-bold cursor-pointer"
     :class="[isColor?'text-[#9272d4]':'text-tertiary']" @click.stop.prevent="onSelect">
    {{ $t('common.copy') }}
  </p>
</template>

<script setup>
import {NOTIFICATION_ERROR_TYPES} from "@/constants";

const props = defineProps({
  code: {
    type: String,
    default: "",
  },
  isColor: {
    type: Boolean,
    default: true
  }
});
const {t} = useI18n();
const onSelect = () => {
  navigator.clipboard.writeText(props.code);
  showNotification({
    type: NOTIFICATION_ERROR_TYPES.SUCCESS, messages: t('copy.code_copied_success')
  });
};
</script>

<style lang="scss" scoped></style>
