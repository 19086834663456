<template>
  <van-popup v-model:show="model" :close-icon-position="$i18n.locale === 'en' ? 'top-right' : 'top-left'"
             :position="!$device.isDesktop?'bottom':'center'"
             :style="{ maxWidth: !$device.isDesktop ? '100%' : '500px',height: 'auto', minWidth:  !$device.isDesktop ? '100%':'500px', borderRadius: !$device.isDesktop ? '0':'10px' }"
             class="p-4" closeable
             v-bind="$attrs">
    <div class="flex-center flex-col h-full py-3 space-y-4">
      <social-share :hidden="false" :referral-url="url"/>
      <div
          class="w-full flex justify-between items-center my-2 px-4 py-3 sm:p-4 rounded-lg border-[1px] border-dashed border-[#cecece] bg-[#f9f9f9]">
        <p class="text-sm font-bold text-[#9272d4]">{{ url }}</p>
        <copy-to-clip-board :code="url"/>
      </div>
    </div>
  </van-popup>
</template>

<script setup>
import {useEventBus} from "@vueuse/core/index";
import {EVENTS} from "~/constants/events";
import SocialShare from "~/components/referral/SocailShare.vue";
import CopyToClipBoard from "~/components/CopyToClipBoard.vue";

const url = ref('');
const model = ref(false);
const {on: onShareOn} = useEventBus(EVENTS.SHARE_ON);

onShareOn(({data: shareData}) => {
  url.value = shareData.url;
  model.value = true;
});
</script>

<style lang="scss" scoped>

</style>