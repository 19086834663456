import validate from "/home/circleci/app/node_modules/nuxt/dist/pages/runtime/validate.js";
import redirection_45global from "/home/circleci/app/middleware/redirection.global.js";
import manifest_45route_45rule from "/home/circleci/app/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  redirection_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  authed: () => import("/home/circleci/app/middleware/authed.js"),
  guest: () => import("/home/circleci/app/middleware/guest.js")
}