<template>
  <div :class="{'hidden':hidden}" class="share flex flex-col items-center justify-center space-y-4">
    <p>{{ $t('referral.share_on') }}</p>
    <div class="flex items-center justify-center justify-end gap-4">
      <ShareNetwork
          v-if="renderComponent"
          :description="offerMessage"
          :title="offerTitle"
          :url="referralUrl"
          network="facebook"
          @click="close"
      >
        <niceone-image :src="$publicPath('/images/facebook.svg')" width="65"/>
      </ShareNetwork>
      <ShareNetwork
          v-if="renderComponent"
          :description="offerMessage"
          :title="offerTitle"
          :url="referralUrl"
          network="twitter"
          @click="close"
      >
        <niceone-image :src="$publicPath('/images/twitter.svg')" width="65"/>
      </ShareNetwork>
      <ShareNetwork
          v-if="renderComponent"
          :description="offerMessage"
          :title="offerTitle"
          :url="referralUrl"
          network="Whatsapp"
          @click="close"
      >
        <niceone-image :src="$publicPath('/images/whatsapp.svg')" width="65"/>
      </ShareNetwork>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  referralUrl: {
    type: String,
    default: ''
  },
  offerTitle: {
    type: String,
    default: ''
  },
  offerMessage: {
    type: String,
    default: ''
  },
  hidden: {
    type: Boolean,
    default: true
  }
});
const renderComponent = ref(true);
const close = async () => {
  renderComponent.value = false;
  await nextTick();
  renderComponent.value = true;
};
</script>

<style lang="scss" scoped>

</style>