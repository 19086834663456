<template>
  <simple-wrapper :full-height="fullHeight" class="bg-light-grey">
    <template #header>
      <van-nav-bar :title="title" @click-left="onClose">
        <template #left>
          <van-icon name="close" size="22"/>
        </template>
      </van-nav-bar>
    </template>
    <div class="p-3 my-5 mx-3 flex flex-col items-center bg-white relative">
      <slot name="description">
        <div class="mb-8 text-center">
          <i18n-t keypath="verify.enter_verification_code_send_to_your_mobile_number">
            <template #number>
              <span dir="ltr">{{ number }}</span>
            </template>
          </i18n-t>
        </div>
      </slot>

      <van-form class="w-full flex flex-col items-center" @submit="emit('verify')">
        <otp-field v-model="code" class="otp-field mb-8 max-w-[300px]"/>
        <van-button :disabled="loading" :loading="loading" block class="font-bold"
                    native-type="submit" type="primary">
          {{ $t('verify.verify') }}
        </van-button>
      </van-form>

      <div class="my-3 w-full">
        <div v-if="timer" class="text-center">{{ $t('verify.resend_code_in') }}</div>
        <van-button v-else :disabled="resendLoading" :loading="resendLoading" block plain
                    type="primary" @click="emit('resend')">{{ $t('verify.resend') }}
        </van-button>
      </div>

      <van-count-down :time="timer" class="color-white" @finish="emit('update:timer', 0)"/>

    </div>
  </simple-wrapper>
</template>

<script setup>
import OtpField from "@/components/form/OTPField";

const props = defineProps({
  modelValue: {
    type: [Number, String],
    default: ""
  },
  title: {
    type: String,
    default: ""
  },
  fullHeight: {
    type: Boolean,
    default: true
  },
  loading: {
    type: Boolean,
    default: false
  },
  resendLoading: {
    type: Boolean,
    default: false
  },
  number: {
    type: String,
    default: ""
  },
  timer: {
    type: Number,
    default: 0
  }
})

const emit = defineEmits(['update:modelValue', 'close', 'verify', 'resend', 'update:timer'])

const code = computed({
  get() {
    return props.modelValue
  },
  set(val) {
    emit('update:modelValue', val)
  }
})
const onClose = () => {
  emit('close')
}
</script>

<style>
.otp-field .van-field__control {
  text-align: center !important;
}
</style>