<template>
  <div>
    <van-popup :close-icon-position="$i18n.locale === 'en' ? 'top-right' : 'top-left'"
               :position="!$device.isDesktop?'bottom':'center'"
               :show="isModelShown"
               :style="{ width: !$device.isDesktop ? '100%' : '500px', height: 'auto' , borderRadius: '10px'}"
               class="p-0 rate-order"
               closeable
               v-bind="$attrs" @close="onClose"
               @open="onOpened">
      <div class="flex flex-col justify-between h-full">
        <div>
          <slot name="title">
            <div class="bg-white px-4 py-3">
              <p class="font-niceone-medium text-lg">
                {{
                  $t("product.reviews.rate_the_order_products", {
                    order_id: deliverOrder.order_id
                  })
                }}
              </p>
              <p class="flex items-center text-grey-muted text-sm">
                <span>{{ $t("product.reviews.order_delivered", {order_date: deliverOrder.order_date}) }}</span>
                <span class="px-1">{{
                    $t("product.reviews.key", {totalPendingProduct: totalProducts})
                  }}</span>
              </p>
            </div>
          </slot>
          <div v-if="isLoading" class="flex-center h-40 sm:h-56">
            <van-loading size="30"/>
          </div>

          <template v-if="!isLoading">
            <progress-bar :loyalty="loyalty" :total="total" :totalPendingProduct="totalPendingProduct"
                          :totalProducts="totalProducts" class="mx-4 mt-4"/>
            <div class="relative">
              <swiper :modules="[SwiperNavigation]"
                      :navigation="{ nextEl: nextRef, prevEl: prevRef}" :slides-per-group="1"
                      :slidesPerView="!$device.isDesktop ? 1.1 : 1"
                      :space-between="16" class="!p-4">

                <swiper-slide v-for="(product, index) in pendingReviewsProduct" :key="`product-${index}`"
                              :class="{'!w-full':totalProducts===1 || total ===1}" class="bg-white rounded-lg">
                  <order-form :loyalty="loyalty" :product="product"
                              @removeProductFromListById="removeProductFromListById"/>
                </swiper-slide>
              </swiper>
              <template v-if="shouldNavigation">
                <div ref="prevRef"
                     class="!flex-center !top-0 !h-full !z-2 swiper-button-prev flip-icon !ltr:left-[3px] !rtl:left-auto !rtl:right-[3px]">
                  <div class="bg-white shadow-md text-secondary w-[24px] h-[24px] rounded-full flex-center">
                    <van-icon name="arrow-left"/>
                  </div>
                </div>
                <div ref="nextRef"
                     class="!flex-center !top-0 !h-full !z-2 swiper-button-next flip-icon !ltr:right-[3px] !rtl:right-auto !rtl:left-[3px]">
                  <div class="bg-white shadow-md text-secondary w-[24px] h-[24px] rounded-full flex-center">
                    <van-icon name="arrow"/>
                  </div>
                </div>
              </template>
            </div>

          </template>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script setup>
import OrderForm from '@/components/ratemodal/Form'
import ProgressBar from '@/components/ratemodal/ProgressBar'
import {getReviewPendingProducts} from "@/plugins/API/review";
import {useAuthStore} from "@/store/auth";
import {track} from "@/composables/useTrackEvent";
import {TRACKERS} from "@/constants/events";
import {useIsEmpty} from "@/composables/useIsEmpty";

const {isEmpty} = useIsEmpty()
const authStore = useAuthStore()
const {user, isRateOrderPopupLoaded, isOrderFeedbackPopupLoaded} = storeToRefs(authStore)
const popup = computed(() => user.value?.is_product_review || null)
const isOrderFeedback = computed(() => user.value?.order_review_pending.length === 0 && (isOrderFeedbackPopupLoaded.value || !isOrderFeedbackPopupLoaded.value))
const isModelShown = computed(() => popup.value && !isRateOrderPopupLoaded.value && isOrderFeedback.value)
const pendingReviewsProduct = ref([])
const loyalty = ref({})
const totalPendingProduct = ref(0)
const total = ref(0)
const nextRef = ref(null)
const prevRef = ref(null)
const shouldNavigation = useNuxtApp().$device.isDesktop || false
const totalProducts = computed(() => pendingReviewsProduct.value.length);
const deliverOrder = computed(() => {
  if (user && user.value.order_product_reviews_pending && user.value.order_product_reviews_pending.length) {
    return user.value.order_product_reviews_pending[0]
  } else {
    return [];
  }
});
const {isLoading, mutateAsync: mutateAsyncPendingProducts} = getReviewPendingProducts()
const getPendingProductReview = (id) => {
  mutateAsyncPendingProducts(id).then(({data}) => {
    pendingReviewsProduct.value = data.order_product_reviews_pending_list.products
    total.value = data.order_product_reviews_pending_list.products.length
    loyalty.value = data.order_product_reviews_pending_list.loyalty
  });
}
const removeProductFromListById = (productId) => {
  const index = pendingReviewsProduct.value.findIndex(product => product.product_id === productId);
  if (index !== -1) {
    totalPendingProduct.value++;
    pendingReviewsProduct.value.splice(index, 1);
  }
  if (totalPendingProduct.value === total.value) {
    onClose()
  }
}
const onOpened = () => {
  if (user.value?.is_product_review) {
    track(TRACKERS.OPEN_PRODUCT_POPUP_REVIEW, {
      loyalty: !isEmpty(loyalty.value),
      orderId: user.value.order_product_reviews_pending[0]?.order_id,
      products: pendingReviewsProduct.value
    })
    getPendingProductReview(user.value.order_product_reviews_pending[0]?.order_id)
  }
}
const onClose = () => {
  track(TRACKERS.CLOSE_PRODUCT_POPUP_REVIEW, {
    loyalty: !isEmpty(loyalty.value),
    orderId: user.value.order_product_reviews_pending[0]?.order_id,
    products: pendingReviewsProduct.value
  })
  isRateOrderPopupLoaded.value = true
}
</script>

<style>
.van-popup.rate-order {
  background-color: #f6f6f6;
}
</style>