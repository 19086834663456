import {getWishlistQuery} from "@/plugins/API/cart"

export const useWishlistStore = defineStore('wishlist', () => {

    const {isLoading, data} = process.client && getWishlistQuery()

    const items = computed(() => data?.value || {})

    const products = computed(() => data?.value?.products || [])

    const loading = computed(() => isLoading?.value || false)

    return {
        loading,
        items,
        products
    }
})