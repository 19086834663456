import revive_payload_client_4sVQNw7RlN from "/home/circleci/app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/home/circleci/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/home/circleci/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/home/circleci/app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/home/circleci/app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_A0OWXRrUgq from "/home/circleci/app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/home/circleci/app/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/home/circleci/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_tbFNToZNim from "/home/circleci/app/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import pwa_client_Eorgdr12jA from "/home/circleci/app/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.mjs";
import vant_lazyload_plugin_W13KNvxip6 from "/home/circleci/app/.nuxt/vant-lazyload.plugin.mjs";
import plugin_w2VlvAEcpa from "/home/circleci/app/node_modules/nuxt-bugsnag/dist/runtime/client/plugin.mjs";
import plugin_t2GMTTFnMT from "/home/circleci/app/node_modules/@nuxtjs/device/dist/runtime/plugin.mjs";
import i18n_yfWm7jX06p from "/home/circleci/app/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import chunk_reload_client_UciE0i6zes from "/home/circleci/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import algolia_client_BeafR9i2W0 from "/home/circleci/app/plugins/algolia.client.js";
import ampli_client_jLK5G2kHx8 from "/home/circleci/app/plugins/ampli.client.js";
import axios_sVCuMR6hEC from "/home/circleci/app/plugins/axios.js";
import captcha_client_eeWg5whV3U from "/home/circleci/app/plugins/captcha.client.js";
import helpers_ipnH1tCNhR from "/home/circleci/app/plugins/helpers.js";
import socailShare_G1IKqT2J01 from "/home/circleci/app/plugins/socailShare.js";
import use_query_trU6nkMqlZ from "/home/circleci/app/plugins/use-query.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_tbFNToZNim,
  pwa_client_Eorgdr12jA,
  vant_lazyload_plugin_W13KNvxip6,
  plugin_w2VlvAEcpa,
  plugin_t2GMTTFnMT,
  i18n_yfWm7jX06p,
  chunk_reload_client_UciE0i6zes,
  algolia_client_BeafR9i2W0,
  ampli_client_jLK5G2kHx8,
  axios_sVCuMR6hEC,
  captcha_client_eeWg5whV3U,
  helpers_ipnH1tCNhR,
  socailShare_G1IKqT2J01,
  use_query_trU6nkMqlZ
]