<template>
  <div>
    <div v-if="items.length" class="px-3 my-1 font-bold text-[16px]">
      <slot name="title"></slot>
    </div>

    <div v-for="(item, index) in items" :key="`search-item-${index}`"
         :class="{ 'border-b': index !== items.length - 1 }"
         class="flex justify-between items-center px-5 py-2 cursor-pointer"
         @click="applySearch(item.search_query,item,index)">
      <div v-html="item[titleKey]"></div>
      <van-icon :name="$publicPath('/images/search-arrow.svg')"></van-icon>
    </div>
  </div>
</template>

<script setup>
import {recentSuggestionWord} from "@/composables/useStoreWord";
import {track} from "~/composables/useTrackEvent";
import {TRACKERS} from "~/constants/events";

const props = defineProps({
  items: {
    type: Array,
    default: () => []
  },
  titleKey: {
    type: String,
    default: 'title'
  },
  sourceName: {
    type: String,
    default: ''
  },
  redirectUsingSearch: {
    type: Boolean,
    default: true
  }
})

const emit = defineEmits(['close'])
const router = useRouter()
const localPath = useLocalePath()
const applySearch = (search_query = '', item = {}, index) => {
  const sourceName = props.sourceName
  if (item)
    recentSuggestionWord({term: item.term ?? item.title, search_query: item.search_query})
  emit('close')
  track(TRACKERS.SEARCH, {item, index, sourceName})

  if (props.redirectUsingSearch) return router.push(localPath({path: '/search', query: {q: search_query}}))

  return router.push(useNuxtApp().$routerUrl(search_query))
}

</script>