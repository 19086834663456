export const track = (name = '', params = null) => {

    if (process.server) return

    import('@/trackers').then(trackers => {
        const callTracker = tracker => {
            if (tracker.hasOwnProperty(name)) {
                tracker[name](params)
            }
        }

        Object.values(trackers).forEach(tracker => callTracker(tracker))
    })

};