export default function useCustomConfig() {
    const config = useRuntimeConfig()

    const publicKeys = Object.fromEntries(
        Object.entries(config.public).map(([key, val]) => [decode(key), decode(val)]),
    );

    return {
        ...config,
        public: {
            ...config.public,
            ...publicKeys
        }
    };
}

const decode = (value) => {
    if (!value || typeof value !== "string") return value
    return Array.from(value).reverse().map(char => String.fromCharCode(char.charCodeAt(0) - 10)).join('');
}