<template>
  <niceone-field
      v-model="model"
      :type="showPassword ? 'text' : 'password'"
      v-bind="$attrs"
  >
    <template #button>
      <div class="flex items-center">
        <div
            v-if="iconType === 'text'"
            class="text-secondary cursor-pointer"
            @click="showPassword = !showPassword"
        >
          {{ showPassword ? $t('auth.hide') : $t('auth.show') }}
        </div>
        <div v-else>
          <van-icon :name="showPassword ? 'closed-eye' : 'eye-o'" class="cursor-pointer"
                    @click="showPassword = !showPassword"/>
        </div>
        <div class="ms-2">
          <slot name="end-button">
          </slot>
        </div>
      </div>
    </template>
  </niceone-field>
</template>

<script setup>
const props = defineProps({
  modelValue: {
    type: String,
    default: ""
  },
  iconType: {
    type: String,
    default: "text" // text || icon
  }
})
const emit = defineEmits(['update:modelValue'])

const model = computed({
  get: () => props.modelValue,
  set: (value) => emit('update:modelValue', value)
})

const showPassword = ref(false)
</script>

<style scoped>

</style>