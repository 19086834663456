<template>
  <div class="bg-white p-3 rounded-lg">
    <p class="text-sm mb-1">
      <span
          class="font-weight-bold">{{
          $t("product.reviews.products", {totalPendingProduct: totalPendingProduct})
        }}</span> {{ $t("product.reviews.were_rated_out_of", {totalProducts: totalProducts}) }}
    </p>
    <van-progress :percentage="getPercentage" :show-pivot="false" color="#997adb" pivot-color="#7232dd"
                  pivot-text="Purple"
                  stroke-width="7"/>
    <p v-if="loyalty.loyalty_points_title" class="text-grey-muted text-sm">
      {{ loyalty.loyalty_points_title }}
    </p>
    <p v-if="loyalty.loyalty_description" class="text-grey-muted text-sm">
      {{ loyalty.loyalty_description }}
    </p>
  </div>
</template>

<script setup>
const props = defineProps({
  totalPendingProduct: {
    type: Number,
    default: 0
  },
  totalProducts: {
    type: Number,
    default: 0
  },
  total: {
    type: Number,
    default: 0
  },
  loyalty: {
    type: Object,
    default: {}
  }
})
const getPercentage = computed(() => {
  const totalPendingProduct = Number(props.totalPendingProduct);
  const total = Number(props.total);
  if (isNaN(totalPendingProduct) || isNaN(total) || total === 0) {
    return 0;
  }
  return (Math.min(totalPendingProduct, total) / total) * 100;
});
</script>

<style lang="scss" scoped>
</style>