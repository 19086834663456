<template>
  <div>
    <!-- Show timer -->
    <div v-if="isValidTimer" class="rounded-full h-4 flex items-center px-2 whitespace-nowrap bg-[#FF001F]">
      <span class="text-[10px] text-white me-1">{{ $t('product.ends_in') }}</span>
      <van-count-down :time="remainingTimeInMilliseconds" class="!text-[10px] !text-white"></van-count-down>
    </div>
    <!-- Otherwise show product tag -->
    <product-tags v-else :product="product"/>
  </div>
</template>

<script setup>
import ProductTags from "~/components/tags/ProductTags.vue";

const props = defineProps({
  product: {
    type: Object,
    default: () => {
    }
  },
})

const hasTimer = computed(() => !!props?.product?.special?.length && !!props?.product?.special?.[0]?.date_end)

const dateEnd = computed(() => hasTimer.value && props?.product?.special[0]?.date_end || 0)
const remainingTimeInMilliseconds = computed(() => {
  const currentTime = new Date().getTime();
  const endTime = dateEnd.value * 1000;
  return Math.max(endTime - currentTime, 0);
})

const isValidTimer = computed(() => {
  const endTime = dateEnd.value * 1000;
  const lessThan24Hours = (endTime - new Date().getTime()) <= (24 * 60 * 60 * 1000);
  return hasTimer.value && endTime > new Date().getTime() && lessThan24Hours;
})
</script>

<style scoped>
</style>