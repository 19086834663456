<template>
  <div class="z-2 top-0 fixed left-0 right-0 h-screen bg-overlay bottom-0"></div>
</template>

<script setup>

</script>

<style lang="scss" scoped>

</style>