<template>
  <van-search :id="id" ref="searchRef" v-bind="attrs" @change.stop="handleChange">
    <template v-for="slot in Object.keys(slots)" v-slot:[slot]="slotProps">
      <slot :name="slot" v-bind="slotProps || {}"/>
    </template>
  </van-search>
</template>

<script setup>
const attrs = useAttrs()
const slots = useSlots()

const emit = defineEmits(['change'])

const searchRef = ref(null)
const id = useId()

const handleChange = (event) => {
  emit('change', event)
}

const focus = () => {
  searchRef.value?.focus();
};

defineExpose({
  focus
})
</script>
