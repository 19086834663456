export const useContentStore = defineStore('content', () => {

    const isPopupLoaded = ref(false)
    const content = ref([])
    const setContent = (cbContent) => {
        content.value = cbContent
    }

    return {
        content,
        isPopupLoaded,
        setContent
    }
})