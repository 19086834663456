export const useConfigStore = defineStore('config', () => {

    const appLoaded = ref(false)
    const setAppLoaded = (state) => {
        appLoaded.value = state
    }

    return {
        appLoaded,
        setAppLoaded
    }
})