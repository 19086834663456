<template>
  <div>
    <van-popup :close-icon-position="$i18n.locale === 'en' ? 'top-right' : 'top-left'"
               :position="!$device.isDesktop?'bottom':'center'"
               :show="!!hasOrderReview && hasOrderReview.hasReview"
               :style="{ width: !$device.isDesktop ? '100%' : '500px', height: 'auto' , borderRadius: '10px'}"
               class="p-0 rate-order"
               closeable
               v-bind="$attrs" @close="onClose">
      <div class="flex flex-col justify-between h-full">
        <div>
          <slot name="title">
            <div class="bg-white px-4 py-3">
              <p class="font-niceone-medium text-lg">
                {{ $t('feedback.how_was_last_order_experience') }}
              </p>
              <p class="flex items-center text-grey-muted text-sm gap-2">
                <span>{{
                    $t('order.the_order_has_been_delivered', {date_added: hasOrderReview?.order?.date_added})
                  }}</span>
                <span>{{ $t('order.order_number_is', {order_id: hasOrderReview?.order?.order_id}) }}</span>
              </p>
            </div>
          </slot>
          <div class="flex flex-col space-y-2 px-4 py-3 bg-white rounded-lg m-4">
            <feedback-row
                v-model="speed"
                :emoji-text="false"
                :title="$t('order.how_do_you_rate_delivery_speed')"
            />
            <feedback-row
                v-model="man"
                :emoji-text="false"
                :title="$t('order.how_do_you_rate_the_shipping_representative')"
            />
            <feedback-row
                v-model="quality"
                :emoji-text="false"
                :title="$t('order.how_do_you_evaluate_the_condition_of_the_products')"
            />
            <label class="text-base">{{ $t('common.notes') }} </label>
            <client-only>
              <niceone-field
                  v-model="comment"
                  :placeholder="$t('feedback.add_your_notes_here')"
                  autosize
                  class="border border-[#ced4da] rounded-md"
                  maxlength="100"
                  rows="2"
                  show-word-limit
                  type="textarea"
              />
            </client-only>
            <van-button :disabled="active" :loading="isLoading" block class="!mt-3 !mb-2" type="primary"
                        @click="sendAction(hasOrderReview.order?.order_id)">
              {{ $t('feedback.save_and_review_products') }}
            </van-button>
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script setup>
import {useAuthStore} from "@/store/auth";
import FeedbackRow from "@/components/feedback/FeedbackRow.vue";
import {reviewOrderMutation} from "@/plugins/API/order";

const authStore = useAuthStore()
const {user, isLoggedIn, isOrderFeedbackPopupLoaded} = storeToRefs(authStore)
const speed = ref(null);
const man = ref(null);
const comment = ref('');
const quality = ref(null);
const currentIndex = ref(0);
const {isLoading, mutateAsync: mutateAsyncReviewOrder} = reviewOrderMutation();
const active = computed(() => {
  return speed.value === null || man.value === null || quality.value === null;
});
const hasOrderReview = computed(() => {
  if (!isLoggedIn.value) return;

  const orders = user.value?.order_review_pending;

  if (orders && orders.length && orders.length > currentIndex.value) {
    return {
      hasReview: orders[currentIndex.value] && !isOrderFeedbackPopupLoaded.value,
      order: orders[currentIndex.value]
    };
  }
  return {
    hasReview: false,
    order: null
  };
});
const onClose = () => {
  speed.value = null
  man.value = null
  quality.value = null
  comment.value = ''
  isOrderFeedbackPopupLoaded.value = true;
  user.value.order_review_pending = []
}
const sendAction = (id) => {
  mutateAsyncReviewOrder({
    order_id: id,
    item_quality: quality.value,
    speed: speed.value,
    delivery_man: man.value,
    other: comment.value
  }).then(({data}) => {
    onClose()
  }).catch(() => {
  })
}
</script>
<style>
.van-popup.rate-order {
  background-color: #f6f6f6;
}
</style>