<template>
  <div class="m-3 h-full">
    <full-page-loader v-if="page === 1" :loading="loading"/>
    <section-layout v-for="(item, index) in messages" :key="`messages-${index}`">
      <nuxt-link :to="getNotification(item)?.link" class="flex items-center" @click="onNavigate(item)">
        <niceone-image :src="getNotification(item)?.src" width="48"/>
        <div class="ms-2 flex flex-col w-full">
          <div class="flex items-center justify-between mb-1">
            <div class="text-base">{{ item.title }}</div>
            <div class="text-boulder text-xs">{{ item.dateAdded }}</div>
          </div>
          <div class="text-xs">{{ item.body }}</div>
        </div>
      </nuxt-link>
    </section-layout>
    <empty-layout v-if="!loading && !messages.length"
                  :description="$t('empty.no_new_messages_available')"
                  :image="$publicPath('/images/placeholder/messages.svg')"
                  :title="$t('empty.messages_box_is_empty')"
                  class="min-h-[600px]"/>
    <infinite-load-more v-model="page" :items-length="messages?.length" :loading="loading" :total="totalMessages"
                        @change="fetch"/>
  </div>
</template>

<script setup>
import {useNotificationsStore} from "@/store/notifications";
import InfiniteLoadMore from "@/components/common/InfiniteLoadMore";
import {NOTIFICATION_ITEM_TYPES} from "@/constants";
import {TRACKERS} from "@/constants/events";
import {track} from "@/composables/useTrackEvent";

const notificationStore = useNotificationsStore()
const {loading, messages, totalMessages, messagesLoaded} = storeToRefs(notificationStore)

const page = ref(1)

onMounted(() => {
  !messagesLoaded.value && fetch()
})

const fetch = () => {
  notificationStore.fetchMessages({page: page.value})
}
const onNavigate = (notify) => {
  if (notify.type === 'ticket')
    track(TRACKERS.OPEN_TICKET_PAGE_FROM_NOTIFICATIONS, {
      ticket_id: notify.reference
    })
}
const
    getNotification = ({type, reference = null, image = null}) => {
      type = type.toLowerCase()
      switch (type) {
        case NOTIFICATION_ITEM_TYPES.ORDER:
          return {
            src: useNuxtApp().$publicPath('/images/notifications/order.svg'),
            link: useNuxtApp().$routerUrl(`/orders/${reference}`)
          }
        case NOTIFICATION_ITEM_TYPES.TICKETS:
          return {
            src: useNuxtApp().$publicPath('/images/notifications/ticket.svg'),
            link: useNuxtApp().$routerUrl(`/tickets/${reference}`)
          }
        case NOTIFICATION_ITEM_TYPES.PRODUCT:
          return {
            src: image,
            link: useNuxtApp().$routerUrl(`-n${reference}`)
          }
        case NOTIFICATION_ITEM_TYPES.REVIEW:
        case NOTIFICATION_ITEM_TYPES.REPLY:
          return {
            src: useNuxtApp().$publicPath('/images/notifications/reply.svg'),
            link: useNuxtApp().$routerUrl('/')
          }
        case NOTIFICATION_ITEM_TYPES.LIKE:
        case NOTIFICATION_ITEM_TYPES.REVIEW_LIKE:
          return {
            src: useNuxtApp().$publicPath('/images/notifications/like.svg'),
            link: useNuxtApp().$routerUrl(`-n${reference}`)
          }
        case NOTIFICATION_ITEM_TYPES.FEEDBACK:
          return {
            src: useNuxtApp().$publicPath('/images/notifications/feedback.svg'),
            link: useNuxtApp().$routerUrl(`/feedback/?ticket_id=${reference}`)
          }
        default:
          return {
            src: '',
            link: useNuxtApp().$routerUrl('/')
          }
      }
    }
</script>

<style scoped>

</style>