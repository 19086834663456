import {useMutation, useQuery} from '@tanstack/vue-query';

export function getVoucherTypesQuery() {
    const queryKey = ['voucher-types'];

    return useQuery(
        queryKey,
        () => {
            return useNuxtApp().$axios("?route=rest/v3/voucher/types").then(response => response?.data || {})
        }
    );
}

export function getVoucherListMutation() {
    return useMutation(
        () => {
            return useNuxtApp().$axios("?route=rest/v3/voucher/vouchersList")
        }
    );
}

export function getVoucherDetailsByIdMutation() {
    return useMutation((
        ({id = ""}) => {
            return useNuxtApp().$axios("?route=rest/v3/voucher/voucherDetails", {params: {id}})
        }
    ))
}

export function submitVoucherMutation() {
    return useMutation(
        ({
             selectedBalance = '',
             balance = '',
             to_phone = "",
             to_name = "",
             from_name = "",
             message = "",
             email = "",
             voucher_color_id = "",
             voucher_card_id = ""
         }) => {
            const params = {
                amount: selectedBalance ? selectedBalance : balance,
                to_phone,
                to_name,
                from_name,
                message,
                voucher_color_id,
                voucher_card_id
            }
            if (email) params.to_email = email

            return useNuxtApp().$axios.post("?route=rest/v3/voucher/request", params)
        }
    );
}

export function checkoutMutation() {
    return useMutation(
        ({cardDetails = "", payment_method = ''} = "") => {
            return useNuxtApp().$axios.post("?route=rest/v3/voucher/modifyPaymentDetails", {
                cardDetails,
                payment_method
            })
        }
    );
}

export function payVoucherMutation() {
    return useMutation(
        (params = '') => {
            return useNuxtApp().$axios.post("?route=rest/v3/voucher/apiCheckout_PAY", {
                ...params,
                cardType: params.type
            })
        }
    );

}

export function getVoucherDetailsByCode() {
    return useMutation((
        (id) => {
            return useNuxtApp().$axios("?route=rest/v3/voucher/voucherDetailsByCode", {params: {code: id}})
        }
    ))
}