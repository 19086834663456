import {useCurrencyStore} from "~/store/currency";

export const ISO_CODES = {
    SA: 'SA',
    KW: 'KW',
    AE: 'AE',
    OM: 'OM',
    BH: 'BH',
    QA: 'QA'
}

export const CURRENCY_CODES = {
    SAR: 'SAR',
    KWD: 'KWD',
    AED: 'AED',
    OMR: 'OMR',
    BHD: 'BHD',
    QAR: 'QAR'
}

export const COUNTRY_CODES = {
    SA: '966',
    KW: '965',
    AE: '971',
    OM: '968',
    BH: '973',
    QA: '974'
}

export const ISO_CODE_BY_CURRENCY = {
    [CURRENCY_CODES.SAR]: ISO_CODES.SA,
    [CURRENCY_CODES.KWD]: ISO_CODES.KW,
    [CURRENCY_CODES.AED]: ISO_CODES.AE,
    [CURRENCY_CODES.OMR]: ISO_CODES.OM,
    [CURRENCY_CODES.BHD]: ISO_CODES.BH,
    [CURRENCY_CODES.QAR]: ISO_CODES.QA
}

export const ISO_CODE_BY_COUNTRY_CODE = {
    [COUNTRY_CODES.SA]: ISO_CODES.SA,
    [COUNTRY_CODES.KW]: ISO_CODES.KW,
    [COUNTRY_CODES.AE]: ISO_CODES.AE,
    [COUNTRY_CODES.OM]: ISO_CODES.OM,
    [COUNTRY_CODES.BH]: ISO_CODES.BH,
    [COUNTRY_CODES.QA]: ISO_CODES.QA
}

export const CURRENCY_CODE_BY_ISO = {
    [ISO_CODES.SA]: CURRENCY_CODES.SAR,
    [ISO_CODES.KW]: CURRENCY_CODES.KWD,
    [ISO_CODES.AE]: CURRENCY_CODES.AED,
    [ISO_CODES.OM]: CURRENCY_CODES.OMR,
    [ISO_CODES.BH]: CURRENCY_CODES.BHD,
    [ISO_CODES.QA]: CURRENCY_CODES.QAR
}

export const COUNTRY_CODE_BY_ISO = {
    [ISO_CODES.SA]: '+966',
    [ISO_CODES.KW]: '+965',
    [ISO_CODES.AE]: '+971',
    [ISO_CODES.OM]: '+968',
    [ISO_CODES.BH]: '+973',
    [ISO_CODES.QA]: '+974',
}

export const DEFAULT_LOCATION_BY_CURRENCY = {
    [CURRENCY_CODES.SAR]:
        {
            lat: 24.713552,
            lng: 46.675297
        },
    [CURRENCY_CODES.KWD]:
        {
            lat: 29.3759,
            lng: 47.9774
        },
    [CURRENCY_CODES.AED]:
        {
            lat: 24.453884,
            lng: 54.377342
        },
    [CURRENCY_CODES.OMR]:
        {
            lat: 23.58589,
            lng: 58.405922
        },
    [CURRENCY_CODES.BHD]:
        {
            lat: 26.228516,
            lng: 50.586048
        },
    [CURRENCY_CODES.QAR]:
        {
            lat: 25.286106,
            lng: 51.534817
        }
}

export const getFormattedCurrency = () => {
    const {t} = useI18n()

    const currencyStore = useCurrencyStore()
    const {currency} = storeToRefs(currencyStore)

    return t(`currencies.${currency.value}`)
}

export const getEnCountryName = () => {

    const currencyStore = useCurrencyStore()
    const {currency} = storeToRefs(currencyStore)

    switch (currency.value) {
        case CURRENCY_CODES.SAR:
            return 'Saudi Arabia'
        case CURRENCY_CODES.KWD:
            return 'Kuwait'
        case CURRENCY_CODES.AED:
            return 'United Arab Emirates'
        case CURRENCY_CODES.BHD:
            return 'Bahrain'
        case CURRENCY_CODES.OMR:
            return 'Oman'
        case CURRENCY_CODES.QAR:
            return 'Qatar'
        default:
            return 'Saudi Arabia'
    }
}