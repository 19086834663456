<template>
  <span>
    <Overlay v-if="overlayPopup" class="z-4 h-[200vh]"/>
    <div
        :class="{ active: show }"
        class="flex items-center z-4 relative main-search"
    >
      <niceone-search
          id="search"
          ref="searchRef"
          v-model="search"
          :clearable="false"
          :placeholder="$t('search.what_are_you_looking_for')"
          :show-action="!!search"
          class="w-full"
          clear-icon=""
          clear-trigger="focus"
          @focus="showSearch"
          @search="searchWord"
          @keyup.enter="searchWord"
      >
          <template #action class="h-[45px] border-b border-b-red-500">
            <van-icon color="#bdbdbd" name="clear" size="24" @click="onCancel"/>
          </template>
      </niceone-search>
    </div>
    <div
        v-if="show"
        class="max-h-[430px] min-h-[150px] overflow-y-auto bg-white absolute z-20 w-full auto-complete"
    >
      <full-page-loader :loading="isLoading"/>
      <search-list
          v-if="!isLoading && search"
          :search="search"
          @close="show = false"
      />

      <div v-if="!isLoading && !search" class="p-3">
        <div v-if="recentSuggestion().length>0" class="mb-2">
          <p class="flex justify-between items-center mb-2">
            <span class="font-niceone-bold">{{ $t('search.what_you_searched_for_recently') }}</span>
            <span
                class="text-grey-muted cursor-pointer"
                @click="clearRecentSearchHistory"
            >{{ $t('search.clear_all') }}</span
            >
          </p>
          <div v-for="(recent, index) in recentSuggestion().slice(-5)" :key="`search-item-${index}`"
               class="flex justify-between items-center py-1 cursor-pointer" @click="getSeoUrl(recent,index, 'saved')">
            <div class="flex items-center gap-2">
              <van-icon :name="$publicPath('/images/clock.svg')"></van-icon>
              <span v-html="recent.term??recent.title"/>
            </div>
            <van-icon :name="$publicPath('/images/search-arrow.svg')"></van-icon>
          </div>
       </div>
        <span v-if="trends.popular_keywords.length>0">
        <div class="mb-2 font-niceone-bold">{{ $t('search.popular_searches') }}</div>
        <div class="flex flex-wrap gap-3">
          <div v-for="(item, index) in trends.popular_keywords" :key="`popular-search-${index}`"
               class="rounded-full border px-2 py-1 text-[13px] cursor-pointer" @click="navigate(item,index,'popular')">
            <nuxt-link :to="$routerUrl('search/?q='+item.keyword)">
            {{ item.keyword }}
          </nuxt-link>
          </div>
        </div>
        </span>
        <span v-if="trends.popular_manufacturers.length>0">
        <div class="mt-3 mb-2 font-niceone-bold">{{ $t('search.recommended_products') }}</div>
        <div class="flex flex-wrap gap-2">
          <nuxt-link v-for="(item, index) in trends.popular_manufacturers" :key="`recommended-product-${index}`"
                     :to="$routerUrl(item.seo_url)"
                     @click="onNavigate(item,index,'brands')">
                 <niceone-image :src="item.image" class="border rounded-md p-2 cursor-pointer" width="80"/>
          </nuxt-link>

        </div>
        </span>
      </div>
    </div>
  </span>
</template>

<script setup>
import {getSearchTrends} from "@/plugins/API/search";
import SearchList from "@/components/home/SearchList";
import Overlay from "@/components/common/Overlay.vue";
import {recentSuggestionWord} from "@/composables/useStoreWord";
import {onClickOutside} from "@vueuse/core";
import {track} from "@/composables/useTrackEvent";
import {TRACKERS} from "@/constants/events";

const props = defineProps({
  overlayPopup: {
    type: Boolean,
    default: false,
  },
});
const router = useRouter();
const localPath = useLocalePath()
const show = ref(false);
const overlayPopup = ref(false);
const search = ref(props.initialValue || "");

const emit = defineEmits(["update:overlayPopup"]);
const enabled = computed(() => props.modelValue);
const {isLoading, data} = getSearchTrends({
  enabled,
});
const trends = computed(() => data?.value || {});
const onCancel = () => {
  track(TRACKERS.SEARCH_TERM, {
    eventLabel: search.value,
    eventAction: 'close'
  })
  show.value = false;
  overlayPopup.value = false;
  search.value = ''
}
const recentSuggestion = () => {
  const allSuggestions = JSON.parse(localStorage.getItem("allSuggestion")) || [];
  return allSuggestions.sort((a, b) => {
    return b.timestamp - a.timestamp;
  });
};
const searchWord = () => {
  track(TRACKERS.SEARCH_TERM, {
    eventLabel: search.value,
    eventAction: 'search_term'
  })
  show.value = false
  overlayPopup.value = false
  recentSuggestionWord({term: search.value, search_query: search.value})
  router.push(localPath({path: '/search', query: {q: search.value}}))
}
const showSearch = () => {
  track(TRACKERS.SEARCH_INITATE)
  show.value = true;
  overlayPopup.value = true;
}
const onSearchClose = () => {
  show.value = false
  overlayPopup.value = false
}
const searchRef = ref(null)
onClickOutside(searchRef, () => {
  onSearchClose()
  onCancel()
})
const clearRecentSearchHistory = () => {
  localStorage.removeItem('allSuggestion')
  onSearchClose()
}
const getSeoUrl = (item, index = 0, source_name = '') => {
  track(TRACKERS.SEARCH, {item, index, source_name})
  router.push(localPath({path: '/search', query: {q: item.search_query}}))
  onSearchClose()
}
const navigate = (item, index = 0, source_name = '') => {
  track(TRACKERS.SEARCH, {item, index, source_name})
  search.value = item.keyword
  recentSuggestionWord({term: item.keyword, search_query: item.keyword})
}
const onNavigate = (product, index = 0, source_name = '') => {
  track(TRACKERS.SEARCH, {product, index, source_name})
}
</script>

<style>
.main-search .van-search__content {
  height: 45px;
  align-items: center;
}

.main-search .van-search {
  padding: 0;
  border-radius: 4px;
}

.active .van-search__content {
  border-radius: 4px 4px 0 0 !important;
  border-bottom: 1px solid #e1e1e1;
  background: #fff;
}

.active .van-search__action {
  height: 45px;
  border-bottom: 1px solid #e1e1e1;
  background: #fff;
  display: flex;
  align-items: center;
  border-radius: 4px 4px 0 0 !important;
}

.auto-complete::-webkit-scrollbar {
  width: 6px;
  background: #f5f5f5;
}

.auto-complete::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #e2e2e2;
}
</style>
