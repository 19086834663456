export const recentSuggestionWord = (keyWord) => {
    let existingSuggestion = JSON.parse(localStorage.getItem('allSuggestion'))
    if (existingSuggestion == null) existingSuggestion = []
    if (
        (JSON.parse(localStorage.getItem('allSuggestion')) === null ||
            JSON.parse(localStorage.getItem('allSuggestion')).length === 0) &&
        keyWord?.term.trim()!== '' &&
        keyWord.search_query.trim() !== ''
    ) {
        existingSuggestion.push(keyWord)
    } else {
        const suggestionExist = existingSuggestion.some(
            obj => obj.search_query === keyWord.search_query
        )
        if (
            !suggestionExist &&
            keyWord?.term.trim() !== '' &&
            keyWord.search_query.trim() !== ''
        ) {
            existingSuggestion.push(keyWord)
        }
    }
    localStorage.setItem('allSuggestion', JSON.stringify(existingSuggestion))
}