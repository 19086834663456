/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * Ampli - A strong typed wrapper for your Analytics
 *
 * This file is generated by Amplitude.
 * To update run 'ampli pull web'
 *
 * Required dependencies: @amplitude/analytics-browser@^1.3.0
 * Tracking Plan Version: 96
 * Build: 1.0.0
 * Runtime: browser:typescript-ampli-v2
 *
 * [View Tracking Plan](https://data.amplitude.com/nice-one/NiceOne%20Tracking%20Plan/events/main/latest)
 *
 * [Full Setup Instructions](https://data.amplitude.com/nice-one/NiceOne%20Tracking%20Plan/implementation/web)
 */

import * as amplitude from '@amplitude/analytics-browser';

export type Environment = 'production' | 'development';

export const ApiKey: Record<Environment, string> = {
  production: '185e44a5502c7914162a222172a64560',
  development: '58f3778aed98b06fc6535286636c1110'
};

/**
 * Default Amplitude configuration options. Contains tracking plan information.
 */
export const DefaultConfiguration: BrowserOptions = {
  plan: {
    version: '96',
    branch: 'main',
    source: 'web',
    versionId: 'dedbd946-9d2e-4308-984b-f7d5a86479e2'
  },
  ...{
    ingestionMetadata: {
      sourceName: 'browser-typescript-ampli',
      sourceVersion: '2.0.0'
    }
  }
};

export interface LoadOptionsBase { disabled?: boolean }

export type LoadOptionsWithEnvironment = LoadOptionsBase & { environment: Environment; client?: { configuration?: BrowserOptions; }; };
export type LoadOptionsWithApiKey = LoadOptionsBase & { client: { apiKey: string; configuration?: BrowserOptions; } };
export type LoadOptionsWithClientInstance = LoadOptionsBase & { client: { instance: BrowserClient; } };

export type LoadOptions = LoadOptionsWithEnvironment | LoadOptionsWithApiKey | LoadOptionsWithClientInstance;

export interface IdentifyProperties {
  birthdate: string;
  gender: string;
  interests: string;
  last_order_date: string;
  /**
   * Shows current loyalty's tier for user
   */
  loyalty_tier?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  orders_count: number;
  /**
   * for non logged in user
   */
  session_id: string;
  skin_color: string;
  /**
   * Total loyalty points collected by the user in last year
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  total_loyalty_points?: number;
  user_id: string;
}

export interface AddAddressProperties {
  address_id: string;
  is_confirmed: boolean;
}

export interface AddCouponProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  cart_total: number;
  coupon_value: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  items_count: number;
}

export interface AddItemToCartProperties {
  adjust_quantity: boolean;
  /**
   * "SAR" as static value always
   */
  currency: string;
  event_source?: any[];
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  items_count: number;
  /**
   * an array of key values that represnts the product
   *
   * | Rule | Value |
   * |---|---|
   * | Unique Items | false |
   */
  product: any[];
  quick_add: boolean;
  /**
   * list\_*id , "shoppingbag" , "banner*\_id" , "component*id" , "search" , "favourite "  other product list(slider*) the id will be the parent_product_id
   */
  source_id: string;
  /**
   * list_name ,"Cart Recommendation Section", "shoppingbag" , "banner_id" , "componentid" , "search" , "favourite "  other product list(slider) the id will be the parent_product_id
   */
  source_name: string;
}

export interface AddToWishlistProperties {
  in_stock: boolean;
  /**
   * an array of key values that represnts the product
   *
   * | Rule | Value |
   * |---|---|
   * | Unique Items | false |
   */
  product: any[];
}

export interface CancelRedemptionProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  canceled_points: number;
}

export interface ChangePaymentMethodProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  cart_total: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  items_count: number;
  payment_method: string;
}

export interface ChangeShipmmentAddressProperties {
  address_id: string;
  is_confirmed: boolean;
  /**
   * **"checkout" : "address_page"**
   */
  source: string;
}

export interface ClickEnableNotificationButtonProperties {
  /**
   * list Name : banner , product*slider , product*listing , search
   */
  source: string;
}

export interface ClickRewardsBannerProperties {
  banner_id: string;
  banner_source: string;
}

export interface CloseProductPopupReviewProperties {
  loyalty_enabled: boolean;
  order_id: string;
  /**
   * an array of key values that represnts the product
   *
   * | Rule | Value |
   * |---|---|
   * | Unique Items | false |
   */
  product: any[];
}

export interface DeleteAddressProperties {
  address_id: string;
  is_confirmed: boolean;
}

export interface EditAddressProperties {
  address_id: string;
  is_confirmed: boolean;
}

export interface ItemReviewedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   * | Min Value | 0 |
   */
  attachment_count: number;
  has_description: boolean;
  loyalty_enabled: boolean;
  order_id: string;
  /**
   * an array of key values that represnts the product
   *
   * | Rule | Value |
   * |---|---|
   * | Unique Items | false |
   */
  product: any[];
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   * | Min Value | 0 |
   * | Max Value | 5 |
   */
  rating_value: number;
}

export interface LoginProperties {
  last_order_date?: string;
  login_type: string;
}

export interface OpenAddressPageProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  cart_total: number;
  currency: string;
}

export interface OpenCartPageProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  cart_total: number;
  currency: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  items_count: number;
  /**
   * an array of key values that represnts the product
   *
   * | Rule | Value |
   * |---|---|
   * | Unique Items | false |
   */
  product: any[];
}

export interface OpenCheckoutPageProperties {
  address_id?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  cart_total: number;
  defualt_payment_option?: string;
  first_time: boolean;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  items_count: number;
  /**
   * an array of key values that represnts the product
   *
   * | Rule | Value |
   * |---|---|
   * | Unique Items | false |
   */
  product: any[];
}

export interface OpenLoyaltyPageProperties {
  tier: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  total_points: number;
}

export interface OpenOrderDetailsPageProperties {
  order_id: string;
  /**
   * list_name ,"Cart Recommendation Section", "shoppingbag" , "banner_id" , "componentid" , "search" , "favourite "  other product list(slider) the id will be the parent_product_id
   */
  source_name: string;
}

export interface OpenPopupForCashOutRewardsProperties {
  total_amount: string;
}

export interface OpenProductListProperties {
  list_id: string;
  list_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  page_number: number;
}

export interface OpenProductPopupReviewProperties {
  loyalty_enabled: boolean;
  order_id: string;
  /**
   * an array of key values that represnts the product
   *
   * | Rule | Value |
   * |---|---|
   * | Unique Items | false |
   */
  product: any[];
}

export interface OpenRedemptionPopupProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  available_redeemable_points: number;
}

export interface OpenRewardsProgramPageProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  total_amount?: number;
}

export interface OpenTicketPageFromNotificationsProperties {
  ticket_id: string;
}

export interface OpenTicketPageFromTicketsListProperties {
  ticket_id: string;
}

export interface OpenVideoPageProperties {
  video_id: string;
  video_name?: string;
}

export interface PlayItemVideoProperties {
  /**
   * an array of key values that represnts the product
   *
   * | Rule | Value |
   * |---|---|
   * | Unique Items | false |
   */
  product: any[];
  video_url: string;
}

export interface RedeemLoyaltyPointsProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  available_redeemable_points: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  redeemed_points: number;
}

export interface RegisterProperties {
  isVerified?: any;
}

export interface RemoveFromWishlistProperties {
  in_stock: boolean;
  /**
   * an array of key values that represnts the product
   *
   * | Rule | Value |
   * |---|---|
   * | Unique Items | false |
   */
  product: any[];
}

export interface SearchProperties {
  /**
   * the location of the banner in the current screen
   *  it will always starts from 0
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  index: number;
  search_term: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  search_type: string;
}

export interface SearchForSuggestionsProperties {
  search_term: string;
}

export interface SelectBannerProperties {
  banner_id: string;
  banner_name?: string;
  component_id?: any;
  /**
   * the parent component id
   */
  componentـid: string;
  /**
   * it holds the index of the banner cell  in the current screen
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  index: number;
  tab_id: string;
  tab_name: string;
  /**
   * shows the type of banner ( banner - product_slider)
   */
  type: string;
}

export interface SelectBrandProperties {
  brand_id: string;
  brand_name: string;
}

export interface SelectItemProperties {
  /**
   * an array of key values that represnts the product
   *
   * | Rule | Value |
   * |---|---|
   * | Unique Items | false |
   */
  product: any[];
  /**
   * list\_*id , "shoppingbag" , "banner*\_id" , "component*id" , "search" , "favourite "  other product list(slider*) the id will be the parent_product_id
   */
  source_id: string;
  /**
   * list_name ,"Cart Recommendation Section", "shoppingbag" , "banner_id" , "componentid" , "search" , "favourite "  other product list(slider) the id will be the parent_product_id
   */
  source_name: string;
  tab_id: string;
  tab_name: string;
}

export interface ShareRewardsBannerProperties {
  banner_id: string;
  banner_source: string;
}

export interface SubmitOrderProperties {
  /**
   * this should be string value and holds $ inside the value
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  $revenue: number;
  coupon_value?: string;
  first_order: boolean;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  items_count: number;
  order_id: string;
  payment_method: string;
  /**
   * an array of key values that represnts the product
   *
   * | Rule | Value |
   * |---|---|
   * | Unique Items | false |
   */
  product: any[];
  rewards?: string;
  shipmment_address_id?: string;
}

export interface SubscriptionCheckoutFailureProperties {
  auto_renewal: boolean;
  payment_method: string;
  plan: string;
}

export interface SubscriptionSuccessfulProperties {
  auto_renewal: boolean;
  payment_method: string;
  plan: string;
}

export interface ViewHomeTabProperties {
  /**
   * on of theses strings "**Home - Categories - Brands - Cart - My Account"**
   */
  name: string;
  tab_id: string;
}

export interface ViewSubscriptionCheckoutProperties {
  plan: string;
}

export class Identify implements BaseEvent {
  event_type = amplitude.Types.SpecialEventType.IDENTIFY;

  constructor(
    public event_properties: IdentifyProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class AddAddress implements BaseEvent {
  event_type = 'add_address';

  constructor(
    public event_properties: AddAddressProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class AddCoupon implements BaseEvent {
  event_type = 'add_coupon';

  constructor(
    public event_properties: AddCouponProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class AddItemToCart implements BaseEvent {
  event_type = 'add_item_to_cart';

  constructor(
    public event_properties: AddItemToCartProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class AddToWishlist implements BaseEvent {
  event_type = 'add_to_wishlist';

  constructor(
    public event_properties: AddToWishlistProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CancelRedemption implements BaseEvent {
  event_type = 'cancel_redemption';

  constructor(
    public event_properties: CancelRedemptionProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ChangePaymentMethod implements BaseEvent {
  event_type = 'change_payment_method';

  constructor(
    public event_properties: ChangePaymentMethodProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ChangeShipmmentAddress implements BaseEvent {
  event_type = 'change_shipmment_address';

  constructor(
    public event_properties: ChangeShipmmentAddressProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ClickEnableNotificationButton implements BaseEvent {
  event_type = 'click_enable_notification_button';

  constructor(
    public event_properties: ClickEnableNotificationButtonProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ClickRewardsBanner implements BaseEvent {
  event_type = 'click_rewards_banner';

  constructor(
    public event_properties: ClickRewardsBannerProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CloseProductPopupReview implements BaseEvent {
  event_type = 'close_product_popup_review';

  constructor(
    public event_properties: CloseProductPopupReviewProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CopyCodeOfRewards implements BaseEvent {
  event_type = 'copy_code_of_rewards';
}

export class DeleteAddress implements BaseEvent {
  event_type = 'delete_address';

  constructor(
    public event_properties: DeleteAddressProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class EditAddress implements BaseEvent {
  event_type = 'edit_address';

  constructor(
    public event_properties: EditAddressProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ItemReviewed implements BaseEvent {
  event_type = 'item_reviewed';

  constructor(
    public event_properties: ItemReviewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class Login implements BaseEvent {
  event_type = 'login';

  constructor(
    public event_properties: LoginProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class OpenAddressPage implements BaseEvent {
  event_type = 'open_address_page';

  constructor(
    public event_properties: OpenAddressPageProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class OpenAllRewardsOffersPage implements BaseEvent {
  event_type = 'open_all_rewards_offers_page';
}

export class OpenCartPage implements BaseEvent {
  event_type = 'open_cart_page';

  constructor(
    public event_properties: OpenCartPageProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class OpenCheckoutPage implements BaseEvent {
  event_type = 'open_checkout_page';

  constructor(
    public event_properties: OpenCheckoutPageProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class OpenHowRewardsWorksPage implements BaseEvent {
  event_type = 'open_how_rewards_works_page';
}

export class OpenInApp implements BaseEvent {
  event_type = 'open_in_app';
}

export class OpenLoginPage implements BaseEvent {
  event_type = 'open_login_page';
}

export class OpenLoyaltyPage implements BaseEvent {
  event_type = 'open_loyalty_page';

  constructor(
    public event_properties: OpenLoyaltyPageProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class OpenNotificationCenterPage implements BaseEvent {
  event_type = 'open_notification_center_page';
}

export class OpenOrderDetailsPage implements BaseEvent {
  event_type = 'open_order_details_page';

  constructor(
    public event_properties: OpenOrderDetailsPageProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class OpenOrderListPage implements BaseEvent {
  event_type = 'open_order_list_page';
}

export class OpenOtpVerificationPage implements BaseEvent {
  event_type = 'open_otp_verification_page';
}

export class OpenPopupForCashOutRewards implements BaseEvent {
  event_type = 'open_popup_for_cash_out_rewards';

  constructor(
    public event_properties: OpenPopupForCashOutRewardsProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class OpenProductList implements BaseEvent {
  event_type = 'open_product_list';

  constructor(
    public event_properties: OpenProductListProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class OpenProductPopupReview implements BaseEvent {
  event_type = 'open_product_popup_review';

  constructor(
    public event_properties: OpenProductPopupReviewProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class OpenRedemptionPopup implements BaseEvent {
  event_type = 'open_redemption_popup';

  constructor(
    public event_properties: OpenRedemptionPopupProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class OpenRegistrationPage implements BaseEvent {
  event_type = 'open_registration_page';
}

export class OpenRewardsProgramPage implements BaseEvent {
  event_type = 'open_rewards_program_page';

  constructor(
    public event_properties?: OpenRewardsProgramPageProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class OpenTicketListPage implements BaseEvent {
  event_type = 'open_ticket_list_page';
}

export class OpenTicketPageFromNotifications implements BaseEvent {
  event_type = 'open_ticket_page_from_notifications';

  constructor(
    public event_properties: OpenTicketPageFromNotificationsProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class OpenTicketPageFromTicketsList implements BaseEvent {
  event_type = 'open_ticket_page_from_tickets_list';

  constructor(
    public event_properties: OpenTicketPageFromTicketsListProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class OpenVideoPage implements BaseEvent {
  event_type = 'open_video_page';

  constructor(
    public event_properties: OpenVideoPageProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class OpenWishlistPage implements BaseEvent {
  event_type = 'open_wishlist_page';
}

export class PlayItemVideo implements BaseEvent {
  event_type = 'play_item_video';

  constructor(
    public event_properties: PlayItemVideoProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class RedeemLoyaltyPoints implements BaseEvent {
  event_type = 'redeem_loyalty_points';

  constructor(
    public event_properties: RedeemLoyaltyPointsProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class Register implements BaseEvent {
  event_type = 'register';

  constructor(
    public event_properties?: RegisterProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class RemoveFromWishlist implements BaseEvent {
  event_type = 'remove_from_wishlist';

  constructor(
    public event_properties: RemoveFromWishlistProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class Search implements BaseEvent {
  event_type = 'search';

  constructor(
    public event_properties: SearchProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SearchForSuggestions implements BaseEvent {
  event_type = 'search_for_suggestions';

  constructor(
    public event_properties: SearchForSuggestionsProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SelectBanner implements BaseEvent {
  event_type = 'select_banner';

  constructor(
    public event_properties: SelectBannerProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SelectBrand implements BaseEvent {
  event_type = 'select_brand';

  constructor(
    public event_properties: SelectBrandProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SelectItem implements BaseEvent {
  event_type = 'select_item';

  constructor(
    public event_properties: SelectItemProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SessionStart implements BaseEvent {
  event_type = 'session_start';
}

export class ShareLinkOfRewards implements BaseEvent {
  event_type = 'share_link_of_rewards';
}

export class ShareRewardsBanner implements BaseEvent {
  event_type = 'share_rewards_banner';

  constructor(
    public event_properties: ShareRewardsBannerProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SubmitOrder implements BaseEvent {
  event_type = 'submit_order';

  constructor(
    public event_properties: SubmitOrderProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SubmitOtpCode implements BaseEvent {
  event_type = 'submit_otp_code';
}

export class SubscriptionCheckoutFailure implements BaseEvent {
  event_type = 'subscription_checkout_failure';

  constructor(
    public event_properties: SubscriptionCheckoutFailureProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SubscriptionSuccessful implements BaseEvent {
  event_type = 'subscription_successful';

  constructor(
    public event_properties: SubscriptionSuccessfulProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class VerificationSuccessful implements BaseEvent {
  event_type = 'verification_successful';
}

export class ViewHomeTab implements BaseEvent {
  event_type = 'view_home_tab';

  constructor(
    public event_properties: ViewHomeTabProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ViewSubscriptionCheckout implements BaseEvent {
  event_type = 'view_subscription_checkout';

  constructor(
    public event_properties: ViewSubscriptionCheckoutProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ViewSubscriptionPage implements BaseEvent {
  event_type = 'view_subscription_page';
}

export type PromiseResult<T> = { promise: Promise<T | void> };

const getVoidPromiseResult = () => ({ promise: Promise.resolve() });

// prettier-ignore
export class Ampli {
  private disabled: boolean = false;
  private amplitude?: BrowserClient;

  get client(): BrowserClient {
    this.isInitializedAndEnabled();
    return this.amplitude!;
  }

  get isLoaded(): boolean {
    return this.amplitude != null;
  }

  private isInitializedAndEnabled(): boolean {
    if (!this.amplitude) {
      console.error('ERROR: Ampli is not yet initialized. Have you called ampli.load() on app start?');
      return false;
    }
    return !this.disabled;
  }

  /**
   * Initialize the Ampli SDK. Call once when your application starts.
   *
   * @param options Configuration options to initialize the Ampli SDK with.
   */
  load(options: LoadOptions): PromiseResult<void> {
    this.disabled = options.disabled ?? false;

    if (this.amplitude) {
      console.warn('WARNING: Ampli is already intialized. Ampli.load() should be called once at application startup.');
      return getVoidPromiseResult();
    }

    let apiKey: string | null = null;
    if (options.client && 'apiKey' in options.client) {
      apiKey = options.client.apiKey;
    } else if ('environment' in options) {
      apiKey = ApiKey[options.environment];
    }

    if (options.client && 'instance' in options.client) {
      this.amplitude = options.client.instance;
    } else if (apiKey) {
      this.amplitude = amplitude.createInstance();
      const configuration = (options.client && 'configuration' in options.client) ? options.client.configuration : {};
      return this.amplitude.init(apiKey, undefined, { ...DefaultConfiguration, ...configuration });
    } else {
      console.error("ERROR: ampli.load() requires 'environment', 'client.apiKey', or 'client.instance'");
    }

    return getVoidPromiseResult();
  }

  /**
   * Identify a user and set user properties.
   *
   * @param userId The user's id.
   * @param properties The user properties.
   * @param options Optional event options.
   */
  identify(
    userId: string | undefined,
    properties: IdentifyProperties,
    options?: EventOptions,
  ): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    if (userId) {
      options = {...options,  user_id: userId};
    }

    const amplitudeIdentify = new amplitude.Identify();
    const eventProperties = properties;
    if (eventProperties != null) {
      for (const [key, value] of Object.entries(eventProperties)) {
        amplitudeIdentify.set(key, value);
      }
    }
    return this.amplitude!.identify(
      amplitudeIdentify,
      options,
    );
  }

 /**
  * Flush the event.
  */
  flush() : PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude!.flush();
  }

  /**
   * Track event
   *
   * @param event The event to track.
   * @param options Optional event options.
   */
  track(event: Event, options?: EventOptions): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude!.track(event, undefined, options);
  }

  /**
   * add_address
   *
   * [View in Tracking Plan](https://data.amplitude.com/nice-one/NiceOne%20Tracking%20Plan/events/main/latest/add_address)
   *
   * triggred when user add address successfully
   *
   * Owner: fedor.chuvilkin@amplitude.com
   *
   * @param properties The event's properties (e.g. address_id)
   * @param options Amplitude event options.
   */
  addAddress(
    properties: AddAddressProperties,
    options?: EventOptions,
  ) {
    return this.track(new AddAddress(properties), options);
  }

  /**
   * add_coupon
   *
   * [View in Tracking Plan](https://data.amplitude.com/nice-one/NiceOne%20Tracking%20Plan/events/main/latest/add_coupon)
   *
   * when user added coupon successfully
   *
   * Owner: fedor.chuvilkin@amplitude.com
   *
   * @param properties The event's properties (e.g. cart_total)
   * @param options Amplitude event options.
   */
  addCoupon(
    properties: AddCouponProperties,
    options?: EventOptions,
  ) {
    return this.track(new AddCoupon(properties), options);
  }

  /**
   * add_item_to_cart
   *
   * [View in Tracking Plan](https://data.amplitude.com/nice-one/NiceOne%20Tracking%20Plan/events/main/latest/add_item_to_cart)
   *
   * triggred when user add product to cart successfully
   *
   * Owner: fedor.chuvilkin@amplitude.com
   *
   * @param properties The event's properties (e.g. adjust_quantity)
   * @param options Amplitude event options.
   */
  addItemToCart(
    properties: AddItemToCartProperties,
    options?: EventOptions,
  ) {
    return this.track(new AddItemToCart(properties), options);
  }

  /**
   * add_to_wishlist
   *
   * [View in Tracking Plan](https://data.amplitude.com/nice-one/NiceOne%20Tracking%20Plan/events/main/latest/add_to_wishlist)
   *
   * Owner: Soad Hayek
   *
   * @param properties The event's properties (e.g. in_stock)
   * @param options Amplitude event options.
   */
  addToWishlist(
    properties: AddToWishlistProperties,
    options?: EventOptions,
  ) {
    return this.track(new AddToWishlist(properties), options);
  }

  /**
   * cancel_redemption
   *
   * [View in Tracking Plan](https://data.amplitude.com/nice-one/NiceOne%20Tracking%20Plan/events/main/latest/cancel_redemption)
   *
   * This event called when user click on cancel used piont as discount at Checkout page.
   *
   * @param properties The event's properties (e.g. canceled_points)
   * @param options Amplitude event options.
   */
  cancelRedemption(
    properties: CancelRedemptionProperties,
    options?: EventOptions,
  ) {
    return this.track(new CancelRedemption(properties), options);
  }

  /**
   * change_payment_method
   *
   * [View in Tracking Plan](https://data.amplitude.com/nice-one/NiceOne%20Tracking%20Plan/events/main/latest/change_payment_method)
   *
   * when the user change the payment method
   *
   * Owner: fedor.chuvilkin@amplitude.com
   *
   * @param properties The event's properties (e.g. cart_total)
   * @param options Amplitude event options.
   */
  changePaymentMethod(
    properties: ChangePaymentMethodProperties,
    options?: EventOptions,
  ) {
    return this.track(new ChangePaymentMethod(properties), options);
  }

  /**
   * change_shipmment_address
   *
   * [View in Tracking Plan](https://data.amplitude.com/nice-one/NiceOne%20Tracking%20Plan/events/main/latest/change_shipmment_address)
   *
   * when the user select an address from addres page
   *
   * Owner: fedor.chuvilkin@amplitude.com
   *
   * @param properties The event's properties (e.g. address_id)
   * @param options Amplitude event options.
   */
  changeShipmmentAddress(
    properties: ChangeShipmmentAddressProperties,
    options?: EventOptions,
  ) {
    return this.track(new ChangeShipmmentAddress(properties), options);
  }

  /**
   * click_enable_notification_button
   *
   * [View in Tracking Plan](https://data.amplitude.com/nice-one/NiceOne%20Tracking%20Plan/events/main/latest/click_enable_notification_button)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. source)
   * @param options Amplitude event options.
   */
  clickEnableNotificationButton(
    properties: ClickEnableNotificationButtonProperties,
    options?: EventOptions,
  ) {
    return this.track(new ClickEnableNotificationButton(properties), options);
  }

  /**
   * click_rewards_banner
   *
   * [View in Tracking Plan](https://data.amplitude.com/nice-one/NiceOne%20Tracking%20Plan/events/main/latest/click_rewards_banner)
   *
   * When user click on banner for rewards offer from ( Home Rewards Page or Offers Rewards Page )
   *
   * @param properties The event's properties (e.g. banner_id)
   * @param options Amplitude event options.
   */
  clickRewardsBanner(
    properties: ClickRewardsBannerProperties,
    options?: EventOptions,
  ) {
    return this.track(new ClickRewardsBanner(properties), options);
  }

  /**
   * close_product_popup_review
   *
   * [View in Tracking Plan](https://data.amplitude.com/nice-one/NiceOne%20Tracking%20Plan/events/main/latest/close_product_popup_review)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. loyalty_enabled)
   * @param options Amplitude event options.
   */
  closeProductPopupReview(
    properties: CloseProductPopupReviewProperties,
    options?: EventOptions,
  ) {
    return this.track(new CloseProductPopupReview(properties), options);
  }

  /**
   * copy_code_of_rewards
   *
   * [View in Tracking Plan](https://data.amplitude.com/nice-one/NiceOne%20Tracking%20Plan/events/main/latest/copy_code_of_rewards)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  copyCodeOfRewards(
    options?: EventOptions,
  ) {
    return this.track(new CopyCodeOfRewards(), options);
  }

  /**
   * delete_address
   *
   * [View in Tracking Plan](https://data.amplitude.com/nice-one/NiceOne%20Tracking%20Plan/events/main/latest/delete_address)
   *
   * when the user delete address success
   *
   * Owner: fedor.chuvilkin@amplitude.com
   *
   * @param properties The event's properties (e.g. address_id)
   * @param options Amplitude event options.
   */
  deleteAddress(
    properties: DeleteAddressProperties,
    options?: EventOptions,
  ) {
    return this.track(new DeleteAddress(properties), options);
  }

  /**
   * edit_address
   *
   * [View in Tracking Plan](https://data.amplitude.com/nice-one/NiceOne%20Tracking%20Plan/events/main/latest/edit_address)
   *
   * Owner: fedor.chuvilkin@amplitude.com
   *
   * @param properties The event's properties (e.g. address_id)
   * @param options Amplitude event options.
   */
  editAddress(
    properties: EditAddressProperties,
    options?: EventOptions,
  ) {
    return this.track(new EditAddress(properties), options);
  }

  /**
   * item_reviewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/nice-one/NiceOne%20Tracking%20Plan/events/main/latest/item_reviewed)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. attachment_count)
   * @param options Amplitude event options.
   */
  itemReviewed(
    properties: ItemReviewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ItemReviewed(properties), options);
  }

  /**
   * login
   *
   * [View in Tracking Plan](https://data.amplitude.com/nice-one/NiceOne%20Tracking%20Plan/events/main/latest/login)
   *
   * Owner: fedor.chuvilkin@amplitude.com
   *
   * @param properties The event's properties (e.g. last_order_date)
   * @param options Amplitude event options.
   */
  login(
    properties: LoginProperties,
    options?: EventOptions,
  ) {
    return this.track(new Login(properties), options);
  }

  /**
   * open_address_page
   *
   * [View in Tracking Plan](https://data.amplitude.com/nice-one/NiceOne%20Tracking%20Plan/events/main/latest/open_address_page)
   *
   * Owner: fedor.chuvilkin@amplitude.com
   *
   * @param properties The event's properties (e.g. cart_total)
   * @param options Amplitude event options.
   */
  openAddressPage(
    properties: OpenAddressPageProperties,
    options?: EventOptions,
  ) {
    return this.track(new OpenAddressPage(properties), options);
  }

  /**
   * open_all_rewards_offers_page
   *
   * [View in Tracking Plan](https://data.amplitude.com/nice-one/NiceOne%20Tracking%20Plan/events/main/latest/open_all_rewards_offers_page)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  openAllRewardsOffersPage(
    options?: EventOptions,
  ) {
    return this.track(new OpenAllRewardsOffersPage(), options);
  }

  /**
   * open_cart_page
   *
   * [View in Tracking Plan](https://data.amplitude.com/nice-one/NiceOne%20Tracking%20Plan/events/main/latest/open_cart_page)
   *
   * Owner: fedor.chuvilkin@amplitude.com
   *
   * @param properties The event's properties (e.g. cart_total)
   * @param options Amplitude event options.
   */
  openCartPage(
    properties: OpenCartPageProperties,
    options?: EventOptions,
  ) {
    return this.track(new OpenCartPage(properties), options);
  }

  /**
   * open_checkout_page
   *
   * [View in Tracking Plan](https://data.amplitude.com/nice-one/NiceOne%20Tracking%20Plan/events/main/latest/open_checkout_page)
   *
   * Owner: fedor.chuvilkin@amplitude.com
   *
   * @param properties The event's properties (e.g. address_id)
   * @param options Amplitude event options.
   */
  openCheckoutPage(
    properties: OpenCheckoutPageProperties,
    options?: EventOptions,
  ) {
    return this.track(new OpenCheckoutPage(properties), options);
  }

  /**
   * open_how_rewards_works_page
   *
   * [View in Tracking Plan](https://data.amplitude.com/nice-one/NiceOne%20Tracking%20Plan/events/main/latest/open_how_rewards_works_page)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  openHowRewardsWorksPage(
    options?: EventOptions,
  ) {
    return this.track(new OpenHowRewardsWorksPage(), options);
  }

  /**
   * open_in_app
   *
   * [View in Tracking Plan](https://data.amplitude.com/nice-one/NiceOne%20Tracking%20Plan/events/main/latest/open_in_app)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  openInApp(
    options?: EventOptions,
  ) {
    return this.track(new OpenInApp(), options);
  }

  /**
   * open_login_page
   *
   * [View in Tracking Plan](https://data.amplitude.com/nice-one/NiceOne%20Tracking%20Plan/events/main/latest/open_login_page)
   *
   * Owner: Soad Hayek
   *
   * @param options Amplitude event options.
   */
  openLoginPage(
    options?: EventOptions,
  ) {
    return this.track(new OpenLoginPage(), options);
  }

  /**
   * open_loyalty_page
   *
   * [View in Tracking Plan](https://data.amplitude.com/nice-one/NiceOne%20Tracking%20Plan/events/main/latest/open_loyalty_page)
   *
   * This event called when the user open loyalty details page. Event indicating when a user opens the loyalty page on the platform
   *
   * @param properties The event's properties (e.g. tier)
   * @param options Amplitude event options.
   */
  openLoyaltyPage(
    properties: OpenLoyaltyPageProperties,
    options?: EventOptions,
  ) {
    return this.track(new OpenLoyaltyPage(properties), options);
  }

  /**
   * open_notification_center_page
   *
   * [View in Tracking Plan](https://data.amplitude.com/nice-one/NiceOne%20Tracking%20Plan/events/main/latest/open_notification_center_page)
   *
   * Owner: Soad Hayek
   *
   * @param options Amplitude event options.
   */
  openNotificationCenterPage(
    options?: EventOptions,
  ) {
    return this.track(new OpenNotificationCenterPage(), options);
  }

  /**
   * open_order_details_page
   *
   * [View in Tracking Plan](https://data.amplitude.com/nice-one/NiceOne%20Tracking%20Plan/events/main/latest/open_order_details_page)
   *
   * Owner: Soad Hayek
   *
   * @param properties The event's properties (e.g. order_id)
   * @param options Amplitude event options.
   */
  openOrderDetailsPage(
    properties: OpenOrderDetailsPageProperties,
    options?: EventOptions,
  ) {
    return this.track(new OpenOrderDetailsPage(properties), options);
  }

  /**
   * open_order_list_page
   *
   * [View in Tracking Plan](https://data.amplitude.com/nice-one/NiceOne%20Tracking%20Plan/events/main/latest/open_order_list_page)
   *
   * Owner: Soad Hayek
   *
   * @param options Amplitude event options.
   */
  openOrderListPage(
    options?: EventOptions,
  ) {
    return this.track(new OpenOrderListPage(), options);
  }

  /**
   * open_otp_verification_page
   *
   * [View in Tracking Plan](https://data.amplitude.com/nice-one/NiceOne%20Tracking%20Plan/events/main/latest/open_otp_verification_page)
   *
   * Owner: Soad Hayek
   *
   * @param options Amplitude event options.
   */
  openOtpVerificationPage(
    options?: EventOptions,
  ) {
    return this.track(new OpenOtpVerificationPage(), options);
  }

  /**
   * open_popup_for_cash_out_rewards
   *
   * [View in Tracking Plan](https://data.amplitude.com/nice-one/NiceOne%20Tracking%20Plan/events/main/latest/open_popup_for_cash_out_rewards)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. total_amount)
   * @param options Amplitude event options.
   */
  openPopupForCashOutRewards(
    properties: OpenPopupForCashOutRewardsProperties,
    options?: EventOptions,
  ) {
    return this.track(new OpenPopupForCashOutRewards(properties), options);
  }

  /**
   * open_product_list
   *
   * [View in Tracking Plan](https://data.amplitude.com/nice-one/NiceOne%20Tracking%20Plan/events/main/latest/open_product_list)
   *
   * It should pushed only one time when I view the product list and that’s it don’t push it with every page
   *
   * Owner: fedor.chuvilkin@amplitude.com
   *
   * @param properties The event's properties (e.g. list_id)
   * @param options Amplitude event options.
   */
  openProductList(
    properties: OpenProductListProperties,
    options?: EventOptions,
  ) {
    return this.track(new OpenProductList(properties), options);
  }

  /**
   * open_product_popup_review
   *
   * [View in Tracking Plan](https://data.amplitude.com/nice-one/NiceOne%20Tracking%20Plan/events/main/latest/open_product_popup_review)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. loyalty_enabled)
   * @param options Amplitude event options.
   */
  openProductPopupReview(
    properties: OpenProductPopupReviewProperties,
    options?: EventOptions,
  ) {
    return this.track(new OpenProductPopupReview(properties), options);
  }

  /**
   * open_redemption_popup
   *
   * [View in Tracking Plan](https://data.amplitude.com/nice-one/NiceOne%20Tracking%20Plan/events/main/latest/open_redemption_popup)
   *
   * This event called when user chooce used loyalty points as discount at checkout page
   *
   * @param properties The event's properties (e.g. available_redeemable_points)
   * @param options Amplitude event options.
   */
  openRedemptionPopup(
    properties: OpenRedemptionPopupProperties,
    options?: EventOptions,
  ) {
    return this.track(new OpenRedemptionPopup(properties), options);
  }

  /**
   * open_registration_page
   *
   * [View in Tracking Plan](https://data.amplitude.com/nice-one/NiceOne%20Tracking%20Plan/events/main/latest/open_registration_page)
   *
   * Owner: Soad Hayek
   *
   * @param options Amplitude event options.
   */
  openRegistrationPage(
    options?: EventOptions,
  ) {
    return this.track(new OpenRegistrationPage(), options);
  }

  /**
   * open_rewards_program_page
   *
   * [View in Tracking Plan](https://data.amplitude.com/nice-one/NiceOne%20Tracking%20Plan/events/main/latest/open_rewards_program_page)
   *
   * Open rewards program page bu click on "Rewards" at Profile
   *
   * @param properties The event's properties (e.g. total_amount)
   * @param options Amplitude event options.
   */
  openRewardsProgramPage(
    properties?: OpenRewardsProgramPageProperties,
    options?: EventOptions,
  ) {
    return this.track(new OpenRewardsProgramPage(properties), options);
  }

  /**
   * open_ticket_list_page
   *
   * [View in Tracking Plan](https://data.amplitude.com/nice-one/NiceOne%20Tracking%20Plan/events/main/latest/open_ticket_list_page)
   *
   * Owner: Soad Hayek
   *
   * @param options Amplitude event options.
   */
  openTicketListPage(
    options?: EventOptions,
  ) {
    return this.track(new OpenTicketListPage(), options);
  }

  /**
   * open_ticket_page_from_notifications
   *
   * [View in Tracking Plan](https://data.amplitude.com/nice-one/NiceOne%20Tracking%20Plan/events/main/latest/open_ticket_page_from_notifications)
   *
   * Owner: Soad Hayek
   *
   * @param properties The event's properties (e.g. ticket_id)
   * @param options Amplitude event options.
   */
  openTicketPageFromNotifications(
    properties: OpenTicketPageFromNotificationsProperties,
    options?: EventOptions,
  ) {
    return this.track(new OpenTicketPageFromNotifications(properties), options);
  }

  /**
   * open_ticket_page_from_tickets_list
   *
   * [View in Tracking Plan](https://data.amplitude.com/nice-one/NiceOne%20Tracking%20Plan/events/main/latest/open_ticket_page_from_tickets_list)
   *
   * Owner: Soad Hayek
   *
   * @param properties The event's properties (e.g. ticket_id)
   * @param options Amplitude event options.
   */
  openTicketPageFromTicketsList(
    properties: OpenTicketPageFromTicketsListProperties,
    options?: EventOptions,
  ) {
    return this.track(new OpenTicketPageFromTicketsList(properties), options);
  }

  /**
   * open_video_page
   *
   * [View in Tracking Plan](https://data.amplitude.com/nice-one/NiceOne%20Tracking%20Plan/events/main/latest/open_video_page)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. video_id)
   * @param options Amplitude event options.
   */
  openVideoPage(
    properties: OpenVideoPageProperties,
    options?: EventOptions,
  ) {
    return this.track(new OpenVideoPage(properties), options);
  }

  /**
   * open_wishlist_page
   *
   * [View in Tracking Plan](https://data.amplitude.com/nice-one/NiceOne%20Tracking%20Plan/events/main/latest/open_wishlist_page)
   *
   * Owner: Soad Hayek
   *
   * @param options Amplitude event options.
   */
  openWishlistPage(
    options?: EventOptions,
  ) {
    return this.track(new OpenWishlistPage(), options);
  }

  /**
   * play_item_video
   *
   * [View in Tracking Plan](https://data.amplitude.com/nice-one/NiceOne%20Tracking%20Plan/events/main/latest/play_item_video)
   *
   * Owner: Moaaz El shazly
   *
   * @param properties The event's properties (e.g. product)
   * @param options Amplitude event options.
   */
  playItemVideo(
    properties: PlayItemVideoProperties,
    options?: EventOptions,
  ) {
    return this.track(new PlayItemVideo(properties), options);
  }

  /**
   * redeem_loyalty_points
   *
   * [View in Tracking Plan](https://data.amplitude.com/nice-one/NiceOne%20Tracking%20Plan/events/main/latest/redeem_loyalty_points)
   *
   * This event called when the user redeemed points. ( Button Redeem at popup )
   *
   * @param properties The event's properties (e.g. available_redeemable_points)
   * @param options Amplitude event options.
   */
  redeemLoyaltyPoints(
    properties: RedeemLoyaltyPointsProperties,
    options?: EventOptions,
  ) {
    return this.track(new RedeemLoyaltyPoints(properties), options);
  }

  /**
   * register
   *
   * [View in Tracking Plan](https://data.amplitude.com/nice-one/NiceOne%20Tracking%20Plan/events/main/latest/register)
   *
   * Owner: fedor.chuvilkin@amplitude.com
   *
   * @param properties The event's properties (e.g. isVerified)
   * @param options Amplitude event options.
   */
  register(
    properties?: RegisterProperties,
    options?: EventOptions,
  ) {
    return this.track(new Register(properties), options);
  }

  /**
   * remove_from_wishlist
   *
   * [View in Tracking Plan](https://data.amplitude.com/nice-one/NiceOne%20Tracking%20Plan/events/main/latest/remove_from_wishlist)
   *
   * Owner: Soad Hayek
   *
   * @param properties The event's properties (e.g. in_stock)
   * @param options Amplitude event options.
   */
  removeFromWishlist(
    properties: RemoveFromWishlistProperties,
    options?: EventOptions,
  ) {
    return this.track(new RemoveFromWishlist(properties), options);
  }

  /**
   * search
   *
   * [View in Tracking Plan](https://data.amplitude.com/nice-one/NiceOne%20Tracking%20Plan/events/main/latest/search)
   *
   * Owner: fedor.chuvilkin@amplitude.com
   *
   * @param properties The event's properties (e.g. index)
   * @param options Amplitude event options.
   */
  search(
    properties: SearchProperties,
    options?: EventOptions,
  ) {
    return this.track(new Search(properties), options);
  }

  /**
   * search_for_suggestions
   *
   * [View in Tracking Plan](https://data.amplitude.com/nice-one/NiceOne%20Tracking%20Plan/events/main/latest/search_for_suggestions)
   *
   * Owner: Soad Hayek
   *
   * @param properties The event's properties (e.g. search_term)
   * @param options Amplitude event options.
   */
  searchForSuggestions(
    properties: SearchForSuggestionsProperties,
    options?: EventOptions,
  ) {
    return this.track(new SearchForSuggestions(properties), options);
  }

  /**
   * select_banner
   *
   * [View in Tracking Plan](https://data.amplitude.com/nice-one/NiceOne%20Tracking%20Plan/events/main/latest/select_banner)
   *
   * for product slider select banner should be triggered only when the user clicks over "see all " button
   *
   * Owner: fedor.chuvilkin@amplitude.com
   *
   * @param properties The event's properties (e.g. banner_id)
   * @param options Amplitude event options.
   */
  selectBanner(
    properties: SelectBannerProperties,
    options?: EventOptions,
  ) {
    return this.track(new SelectBanner(properties), options);
  }

  /**
   * select_brand
   *
   * [View in Tracking Plan](https://data.amplitude.com/nice-one/NiceOne%20Tracking%20Plan/events/main/latest/select_brand)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. brand_id)
   * @param options Amplitude event options.
   */
  selectBrand(
    properties: SelectBrandProperties,
    options?: EventOptions,
  ) {
    return this.track(new SelectBrand(properties), options);
  }

  /**
   * select_item
   *
   * [View in Tracking Plan](https://data.amplitude.com/nice-one/NiceOne%20Tracking%20Plan/events/main/latest/select_item)
   *
   * Owner: fedor.chuvilkin@amplitude.com
   *
   * @param properties The event's properties (e.g. product)
   * @param options Amplitude event options.
   */
  selectItem(
    properties: SelectItemProperties,
    options?: EventOptions,
  ) {
    return this.track(new SelectItem(properties), options);
  }

  /**
   * session_start
   *
   * [View in Tracking Plan](https://data.amplitude.com/nice-one/NiceOne%20Tracking%20Plan/events/main/latest/session_start)
   *
   * Owner: fedor.chuvilkin@amplitude.com
   *
   * @param options Amplitude event options.
   */
  sessionStart(
    options?: EventOptions,
  ) {
    return this.track(new SessionStart(), options);
  }

  /**
   * share_link_of_rewards
   *
   * [View in Tracking Plan](https://data.amplitude.com/nice-one/NiceOne%20Tracking%20Plan/events/main/latest/share_link_of_rewards)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  shareLinkOfRewards(
    options?: EventOptions,
  ) {
    return this.track(new ShareLinkOfRewards(), options);
  }

  /**
   * share_rewards_banner
   *
   * [View in Tracking Plan](https://data.amplitude.com/nice-one/NiceOne%20Tracking%20Plan/events/main/latest/share_rewards_banner)
   *
   * When user share banner for rewards offer from ( Home Rewards Page or Offers Rewards Page )
   *
   * @param properties The event's properties (e.g. banner_id)
   * @param options Amplitude event options.
   */
  shareRewardsBanner(
    properties: ShareRewardsBannerProperties,
    options?: EventOptions,
  ) {
    return this.track(new ShareRewardsBanner(properties), options);
  }

  /**
   * submit_order
   *
   * [View in Tracking Plan](https://data.amplitude.com/nice-one/NiceOne%20Tracking%20Plan/events/main/latest/submit_order)
   *
   * Owner: fedor.chuvilkin@amplitude.com
   *
   * @param properties The event's properties (e.g. $revenue)
   * @param options Amplitude event options.
   */
  submitOrder(
    properties: SubmitOrderProperties,
    options?: EventOptions,
  ) {
    return this.track(new SubmitOrder(properties), options);
  }

  /**
   * submit_otp_code
   *
   * [View in Tracking Plan](https://data.amplitude.com/nice-one/NiceOne%20Tracking%20Plan/events/main/latest/submit_otp_code)
   *
   * Owner: Soad Hayek
   *
   * @param options Amplitude event options.
   */
  submitOtpCode(
    options?: EventOptions,
  ) {
    return this.track(new SubmitOtpCode(), options);
  }

  /**
   * subscription_checkout_failure
   *
   * [View in Tracking Plan](https://data.amplitude.com/nice-one/NiceOne%20Tracking%20Plan/events/main/latest/subscription_checkout_failure)
   *
   * Owner: Soad Hayek
   *
   * @param properties The event's properties (e.g. auto_renewal)
   * @param options Amplitude event options.
   */
  subscriptionCheckoutFailure(
    properties: SubscriptionCheckoutFailureProperties,
    options?: EventOptions,
  ) {
    return this.track(new SubscriptionCheckoutFailure(properties), options);
  }

  /**
   * subscription_successful
   *
   * [View in Tracking Plan](https://data.amplitude.com/nice-one/NiceOne%20Tracking%20Plan/events/main/latest/subscription_successful)
   *
   * Owner: Soad Hayek
   *
   * @param properties The event's properties (e.g. auto_renewal)
   * @param options Amplitude event options.
   */
  subscriptionSuccessful(
    properties: SubscriptionSuccessfulProperties,
    options?: EventOptions,
  ) {
    return this.track(new SubscriptionSuccessful(properties), options);
  }

  /**
   * verification_successful
   *
   * [View in Tracking Plan](https://data.amplitude.com/nice-one/NiceOne%20Tracking%20Plan/events/main/latest/verification_successful)
   *
   * Owner: Soad Hayek
   *
   * @param options Amplitude event options.
   */
  verificationSuccessful(
    options?: EventOptions,
  ) {
    return this.track(new VerificationSuccessful(), options);
  }

  /**
   * view_home_tab
   *
   * [View in Tracking Plan](https://data.amplitude.com/nice-one/NiceOne%20Tracking%20Plan/events/main/latest/view_home_tab)
   *
   * when user select a home tab from the top of the page , this event should also be triggred when we show the home default tab
   *
   * Owner: fedor.chuvilkin@amplitude.com
   *
   * @param properties The event's properties (e.g. name)
   * @param options Amplitude event options.
   */
  viewHomeTab(
    properties: ViewHomeTabProperties,
    options?: EventOptions,
  ) {
    return this.track(new ViewHomeTab(properties), options);
  }

  /**
   * view_subscription_checkout
   *
   * [View in Tracking Plan](https://data.amplitude.com/nice-one/NiceOne%20Tracking%20Plan/events/main/latest/view_subscription_checkout)
   *
   * Owner: Soad Hayek
   *
   * @param properties The event's properties (e.g. plan)
   * @param options Amplitude event options.
   */
  viewSubscriptionCheckout(
    properties: ViewSubscriptionCheckoutProperties,
    options?: EventOptions,
  ) {
    return this.track(new ViewSubscriptionCheckout(properties), options);
  }

  /**
   * view_subscription_page
   *
   * [View in Tracking Plan](https://data.amplitude.com/nice-one/NiceOne%20Tracking%20Plan/events/main/latest/view_subscription_page)
   *
   * Owner: Soad Hayek
   *
   * @param options Amplitude event options.
   */
  viewSubscriptionPage(
    options?: EventOptions,
  ) {
    return this.track(new ViewSubscriptionPage(), options);
  }
}

export const ampli = new Ampli();

// BASE TYPES
type BrowserOptions = amplitude.Types.BrowserOptions;

export type BrowserClient = amplitude.Types.BrowserClient;
export type BaseEvent = amplitude.Types.BaseEvent;
export type IdentifyEvent = amplitude.Types.IdentifyEvent;
export type GroupEvent = amplitude.Types.GroupIdentifyEvent;
export type Event = amplitude.Types.Event;
export type EventOptions = amplitude.Types.EventOptions;
export type Result = amplitude.Types.Result;
