import {getCartQuery} from "@/plugins/API/cart";

export const useCartStore = defineStore("cart", () => {
    const {isLoading, data} = process.client && getCartQuery();

    const showCart = ref(false)

    const selectedOptionsAttributes = ref({
        rightEyeQuantity: 1,
        leftEyeQuantity: 1,
        leftEyeSelectedOption: null,
        rightEyeSelectedOption: null,
    });

    const hasDifferPowerOptions = ref(false)

    const items = computed(() => data?.value || {});

    const cart = computed(() => items.value?.cart || {});

    const loading = computed(() => isLoading?.value || false);

    const count = computed(() => cart.value?.total_product_count || 0);

    const cartProducts = computed(() => cart.value?.products);

    const allInStock = computed(() =>
        cartProducts.value?.every((item) => !!item?.stock)
    );
    const $resetProductAttributes = () => {

        selectedOptionsAttributes.value = {
            rightEyeQuantity: 1,
            leftEyeQuantity: 1,
            leftEyeSelectedOption: null,
            rightEyeSelectedOption: null,
        };
    };

    const getCartProductCountById = (id, product = null) => {

        if (!!product?.option_detail) {
            return cartProducts.value?.find(
                (item) => Number(item.product_id) === Number(id) && Number(product?.option_detail?.product_option_value_id) === Number(item.option[0].product_option_value_id)
            )?.quantity || 0
        }

        return cartProducts.value?.find(
            (item) => Number(item.product_id) === Number(id)
        )?.quantity || 0
    };

    const getCartProductKeyById = (id) => {
        return cartProducts?.value?.find(item => Number(item.product_id) === Number(id))?.key || null
    }

    return {
        showCart,
        loading,
        cart,
        items,
        count,
        cartProducts,
        allInStock,
        getCartProductCountById,
        selectedOptionsAttributes,
        $resetProductAttributes,
        hasDifferPowerOptions,
        getCartProductKeyById
    };
});
